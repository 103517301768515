import React, { useState } from "react";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./EditorField.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

/**
 * Editor de texto usando el paquete React Draft Wysiwyg
 * @subcategory Crud
 * @param {object} props
 * @param {string=} props.defaultValue El texto a mostrar en el editor
 * @param {Function} props.onChange La función a llamar cuando se cambia el texto del editor
 *
 * @category Administración
 * @version 1.1.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function EditorField({ value, onChange, ...props }) {
  const blocksFromHtml = htmlToDraft(`${value ? value : ""}`);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  /**
   * Hook para actualizar le estado del editor
   */
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );
  /**
   * Función que es llamada cuando cambia el editor y actualizar el hook y
   * llama a la función dada en las props
   *
   * @param {object} editorState El estado del editor
   *
   * @version 1.0.1
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const onEditorStateChange = (editorState) => {
    onChange(
      editorState && draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    setEditorState(editorState);
  };

  return (
    <Editor
      editorState={editorState}
      wrapperClassName="EditorField-wrapper"
      editorClassName="EditorField-editor"
      toolbarClassName="EditorField-toolbar"
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          //"fontFamily",
          "list",
          "textAlign",
          //"colorPicker",
          "link",
          //"embedded",
          "emoji",
          "image",
          //"remove",
          "history",
        ],
      }}
      onEditorStateChange={onEditorStateChange}
    />
  );
}

export default EditorField;
