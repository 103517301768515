import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import parse from "html-react-parser";
import Agradecimiento from "./Agradecimiento";
/**
 * Funcion para habilitar animación de entrada vertical
 * 
 * @category Dialogs
 * @version 1.0.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Modal que muestra instruccion para las rondas o mensajes de agradecimiento
 * 
 * @param {object} props - Propiedades
 * @param {object=} props.ronda - El número de la ronda
 * @param {boolean} props.flagOpen - bandera para mostrar o no el modal
 * @param {function} props.handleClose - Callback para cerrar el modal
 * 
 * @category Dialogs
 * @version 2.0.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Instruccion({ ronda, flagOpen, handleClose }) {
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={flagOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component="div" variant="h4" color="primary" align="center">
          {ronda && `Ronda ${ronda.tipo_ronda + 1}`}
        </Typography>
        <Typography component="div" variant="h4" color="primary" align="center">
          {ronda && "Instrucciones"}
        </Typography>
      </DialogTitle>
      <DialogContent className="informacion">
        {ronda ? (
          ronda.instrucciones && (
            <Typography>{parse(ronda.instrucciones)}</Typography>
          )
        ) : (
          <Agradecimiento />
        )}
        <div className="Login_logo_section">
          <img
            alt="logo_nuevo_leon"
            className="Login_logo"
            src="https://delphiconl.s3.us-east-2.amazonaws.com/front/nuevo_leon_logo.svg"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={handleClose}>
          {ronda ? "¡Empecemos!" : "Cerrar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Instruccion;
