import { Button, Snackbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkDialogInstruccion } from '../../Redux/Actions';
import Api, { handleError } from '../../Restful/Api';
import Lista from './Lista/Lista';
import Panel from './Panel';
import UserHeader from './UserHeader/UserHeader';
import Instruccion from '../Dialogs/Instruccion/Instruccion';
import Documentacion from '../Dialogs/Documentacion';
/**
 * Componente que muestra las aspiraciones y objectivos de una ronda
 *
 * @param {object} props
 * @param {object} props.ronda la ronda actual
 * @param {boolean} props.intruccionVista bandera para mostrar modal
 * @param {funcion} props.hideInstruccion función para ocultar el modal
 *
 * @category Votación
 * @version 1.6.1
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Ronda({ ejercicio, ronda, intruccionVista, hideInstruccion }) {
  /**
   * Hook para manipular una lista de elementos (aspiraciones)
   */
  const [aspiraciones, setAspiraciones] = useState([]);
  /**
   * Hook para manipular una lista de elementos (objectivos)
   */
  const [objetivos, setObjetivos] = useState([]);
  /**
   * Hook para manipular la bandera del estado de carga
   */
  const [loading, setLoading] = useState(false);
  /**
   * Hook para el Snackbar
   */
  const [message_snackbar, setMessageSnackbar] = useState(false);
  /**
   * Hook para manipular el texto mostrado en el Snackbar
   */
  const [message, setMessage] = useState('');
  /**
   * Hook para manipular el estado de visionado del modal <Instruccion/> con el contenido <Agradecimiento/>
   */
  const [showAgradecimiento, setShowAgradecimiento] = useState(false);
  /**
   * Hook para manipular el estado de visionado del modal <Documentacion/>
   */
  const [showDocumentacion, setShowDocumentacion] = useState(false);
  /**
   * mostrar un mensaje en el fondo de la pantalla
   * @param {string} message - El mensaje a mostrar
   * @version 1.0.0
   */
  const showSnackbar = (message) => {
    setMessage(message);
    setMessageSnackbar(true);
  };
  /**
   * Genera una cadena para construir una url segun el tipo de ronda del elemento
   * @param {number} tipo_ronda - Tipo de ronda
   * @returns {string|undefined} - Cadena del tipo de texto
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const getTipoRonda = (tipo_ronda) => {
    switch (tipo_ronda) {
      case 0:
        return 'votacion-propuestas';
      case 1:
        return 'votacion';
      case 2:
        return 'metas';
      default:
        break;
    }
  };
  /**
   * Consumo del API y asignación de arreglos
   */
  useEffect(() => {
    setLoading(true);
    Api.post('/api/panel', {
      rondaId: ronda.id,
    })
      .then((response) => {
        setLoading(false);
        //console.log(response);
        switch (response.status) {
          case 200:
            let aspiraciones = response.data.aspiraciones;
            setAspiraciones(
              aspiraciones.map((aspiracion) => {
                return {
                  id: aspiracion.id,
                  nombre: aspiracion.descripcion,
                  estatus: aspiracion.estatus,
                  link: aspiracion.indicadoresMeta
                    ? `/${getTipoRonda(ronda.tipo_ronda)}/aspiracion/${
                        aspiracion.id
                      }`
                    : null,
                };
              })
            );

            let objetivos = response.data.objetivos;
            setObjetivos(
              objetivos.map((objetivo) => {
                return {
                  id: objetivo.id,
                  nombre: objetivo.descripcion,
                  estatus: objetivo.estatus,
                  link: objetivo.indicadoresMeta
                    ? `/${getTipoRonda(ronda.tipo_ronda)}/objetivo/${
                        objetivo.id
                      }`
                    : null,
                  subfilas: objetivo.lineasEstrategicas?.map((linea) => {
                    return {
                      id: linea.id,
                      nombre: linea.descripcion,
                      estatus: linea.estatus,
                      link: linea.indicadoresMeta
                        ? `/${getTipoRonda(ronda.tipo_ronda)}/linea/${linea.id}`
                        : null,
                    };
                  }),
                };
              })
            );
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        setLoading(false);
        showSnackbar(handleError(error));
      });
    // eslint-disable-next-line
  }, [ronda]);
  /**
   * Verificar si se ha completado todo el ejercicio y mostrar modal de agradecimiento
   */
  useEffect(() => {
    if (aspiraciones.length > 0 && objetivos.length > 0) {
      let aspiraciones_completadas =
        aspiraciones.length &&
        aspiraciones
          .map((aspiracion) => {
            return aspiracion.estatus === 2;
          })
          .reduce((prev, current) => {
            return prev && current;
          }, true);
      let objetivos_lineas_completadas =
        objetivos.length &&
        objetivos
          .map((objectivo) => {
            return (
              objectivo.estatus === 2 &&
              objectivo.subfilas
                .map((linea) => {
                  return linea.estatus === 2;
                })
                .reduce((prev, current) => {
                  return prev && current;
                }, true)
            );
          })
          .reduce((prev, current) => {
            return prev && current;
          }, true);
      if (aspiraciones_completadas && objetivos_lineas_completadas) {
        hideInstruccion();
        setShowAgradecimiento(true);
      }
    }
    // eslint-disable-next-line
  }, [objetivos]);

  return (
    <Panel>
      <UserHeader />
      <Lista
        nombre={ronda.descripcion}
        loading={loading}
        titulo={ejercicio.nombreCategoriaA}
        titulo_accion="Estado"
        filas={aspiraciones}
      />
      <Lista
        loading={loading}
        titulo={`${ejercicio.nombreCategoriaB} y ${ejercicio.nombreSubCategoriaB1}`}
        titulo_accion="Estado"
        filas={objetivos}
      />
      {/* <Lista
        loading={loading}
        titulo="Líneas estratégicas"
        titulo_accion="Estado"
        filas={lineas}
      /> */}
      <div className="bottom_button">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowDocumentacion(true)}
        >
          Documentos de ayuda
        </Button>
        <Link className="link_router" to="/rondas">
          <Button variant="contained" color="secondary">
            Regresar a rondas
          </Button>
        </Link>
      </div>
      {/* Snackbar section */}
      <Snackbar
        message={message}
        open={message_snackbar}
        autoHideDuration={5000}
        onClose={() => {
          setMessageSnackbar(false);
        }}
      />
      {/* Dialogs */}
      {/* Instrucciones */}
      <Instruccion
        ronda={ronda}
        flagOpen={intruccionVista}
        handleClose={() => {
          if (intruccionVista) hideInstruccion();
        }}
      />
      {/* Agradecimiento */}
      <Instruccion
        flagOpen={showAgradecimiento}
        handleClose={() => setShowAgradecimiento(false)}
      />
      {/* Documentacion */}
      <Documentacion
        flag_open={showDocumentacion}
        handleClose={() => setShowDocumentacion(false)}
      />
    </Panel>
  );
}

const mapStateToProps = (state) => ({
  ronda: state.ronda,
  intruccionVista: state.checkDialogs.dialog_instruccion,
  ejercicio: state.ejercicio,
});

const mapDispatchToProps = (dispatch) => {
  return {
    hideInstruccion: () => dispatch(checkDialogInstruccion()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ronda);
