import { Button, CircularProgress } from "@material-ui/core";
import React from "react";
import "./LoadingButton.css";
/**
 * Extensión de un boton con las capacidades de proporcionar feedback al ejecutar acciones asíncronas
 * @param {Object} props - Propiedades
 * @param {string=} props.className - Clases a propagar en el boton
 * @param {boolean} props.loading - bandera para cambiar de estado el botón
 * @param {string} props.text - Texto a mostrar en el botón en el estado normal
 * @param {string} props.loading_text - Texto a mostrar cuando la bandera loading es verdadera
 * @param {function} props.onClick - función que ejecutará el botón
 *
 * @category Helpers
 * @version 2.0.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function LoadingButton({
  className,
  loading,
  text,
  loading_text,
  onClick,
  ...rest
}) {
  return (
    <Button
      data-testid="loading-button"
      className={className}
      disabled={loading}
      onClick={onClick}
      {...rest}
    >
      {loading ? loading_text : text}
      <CircularProgress
        data-testid="spinner-progress"
        className={`button_loading_icon ${loading ? "loading" : ""}`}
        size=".9rem"
        color="inherit"
      />
    </Button>
  );
}

export default LoadingButton;
