import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import Formulario from "../../Admin/CRUD/Formulario/Formulario";

import "./PropuestaForm.css";
/**
 * Formulario para poder ingresar elementos nuevos (propuestas)
 * 
 * @param {Object} props - Propiedades
 * @param {number} props.idIndicador - Id del elemento al cual se le agregará esta propuesta
 * @param {"aspiracion"|"objectivo"|"linea"} props.tipo - Tipo del elemento
 * @param {boolean} props.flag_open - bandera para el visionado del modal
 * @param {function} props.handleClose - Callback para cerrar el modal
 * @param {Array<*>} props.propuestas - lista de elementos propuestos
 * @param {function} props.setPropuestas - Hook para manipular lista de elementos propuestos
 *
 * @category Votación
 * @version 1.1.1
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function PropuestaForm({
  idIndicador,
  tipo,
  flag_open,
  handleClose,
  setPropuestas,
}) {
  /**
   * Obtiene la información previamente validada del formulario y lo agrega al lista
   * de elementos actuales (propuestas)
   * @param {object} data información del formulario
   *
   * @version 1.1.1
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const onSubmit = (data) => {
    setPropuestas({
      ...data,
      id: null,
      aspiracionId: tipo === "aspiracion" ? idIndicador : null,
      objetivoId: tipo === "objetivo" ? idIndicador : null,
      lineaEstrategicaId: tipo === "linea" ? idIndicador : null,
    });
    handleClose();
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={flag_open}
      onClose={handleClose}
      className="propuesta_dialog"
    >
      <DialogTitle>Propuesta de nuevo indicador</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Qué otro indicador considera que debe incluirse?
        </DialogContentText>
        <Formulario
          onCancel={handleClose}
          saveChanges={onSubmit}
          inputs={[
            {
              nombre: "nombre",
              etiqueta: "Nombre del indicador",
              validacion: { required: "El campo es requerido" },
              config: { fullWidth: true, required: true },
              grid: { xs: 12 },
            },
            {
              nombre: "justificacion",
              etiqueta: "¿Por qué incluir este nuevo indicador?",
              validacion: { required: "El campo es requerido" },
              config: { fullWidth: true, required: true },
              grid: { xs: 12 },
            },
            {
              nombre: "fuente",
              etiqueta: "Fuente del indicador",
              config: { fullWidth: true },
              grid: { xs: 12 },
            },
            {
              nombre: "url",
              etiqueta: "Url o dirección donde podríamos encontrar información",
              config: { fullWidth: true },
              grid: { xs: 12 },
            },
          ]}
        />
      </DialogContent>
    </Dialog>
  );
}

export default PropuestaForm;
