/**
 * @module Redux/Reducers
 */
/**
 * Reducer usado por redux para manipular el usuario actual
 * @param {object} [state={}] - Estado inicial
 * @param {object} action - parametros para realizar
 * @param {string} action.type la accion a realizar
 * @param {*=} action.payload datos para realizar la acción
 *
 * @returns {object} - Estado modificado
 *
 * @version 1.0.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
const userReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_USER":
      return (state = action.payload);
    case "DELETE_USER":
      return (state = {});
    default:
      return state;
  }
};

export default userReducer;
