import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import MultiInputField from "../MultiInputField/MultiInputField";
import EditorField from "../EditorField/EditorField";
import { Controller, useForm } from "react-hook-form";

/**
 * Formulario dinamico para capturar información
 * @param {object} props - props
 * @param {object[]} [props.inputs=[]]  - los inputs a mostrar
 * @param {string} props.inputs[].nombre  - nombre del input
 * @param {string|function=} props.inputs[].etiqueta  - etiqueta del input
 * @param {'input'|'textarea'|'number'|'array'|'checkbox'|'switch'|'hidden'|'multiple'|'editor'} [props.inputs[].tipo=input]  - tipo de input
 * @param {Array<object>=} props.inputs[].opciones - Opciones para el input tipo array o el tipo checkbox
 * @param {object=} props.inputs[].validacion  - Validaciones {@link https://react-hook-form.com/api/#register|React Hook Form}
 * @param {object=} props.inputs[].config  - Props para el input a imprimir
 * @param {object=} props.inputs[].grid  - Configuración para el layout  {@link https://material-ui.com/components/grid/|Grid}
 * @param {object=} [props.data = {}]  - información del formulario en caso de ser una edición
 * @param {string=} [props.etiquetaBotonGuardado=Guardar] - La etiqueta a mostrar en el botón de guardado
 *
 * @param {function} props.saveChanges  - Funcion que captura la información y la devuelve de regreso
 * @param {function} props.onCancel  - Funcion si se da clic en el botón de cancelar
 * @param {function} props.onChanges - Función que permite manipular los cambios del formulario y hacer cambios
 *@param {string|Array<string>=} props.watchedInputs Inputs a observar y devolver en la función onChanges
 *
 * @category Administración
 * @subcategory Crud
 * @version 1.1.1
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Formulario({
  inputs = [],
  data = {},
  saveChanges,
  onCancel,
  etiquetaBotonGuardado = "Guardar",
  onChanges,
  watchedInputs = "",
  ...props
}) {
  /**
   * Hook para formulario de filtrado
   */
  const { handleSubmit, register, errors, watch, setValue, control } = useForm({
    mode: "onBlur",
  });
  /**
   * Hook que observa el o los inputs a devolver en la función onChanges
   */
  const watchAllFields = watch(watchedInputs);

  /**
   * Se obtiene el error de manera dinamica
   * @param {object | undefined} error El error dentro del objecto de hook para el formulario
   * @returns {string} El mensage de error o una cadena vacía
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const obtenerError = (error) => {
    if (error) {
      return error[1].message;
    } else {
      return "";
    }
  };
  /**
   * Se busca el dato que coincida con el input
   * @param {object} input el input a buscar en los datos de props
   * @returns {string | boolean} el valor encontrado o un valor vacio
   * @version 1.0.2
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const obtenerValor = (input) => {
    const dato = Object.entries(data).find(
      ([key, value]) => key === input.nombre
    );
    if (dato) {
      return dato[1];
    } else {
      if (input.tipo === "multiple" || input.multiple) {
        return [];
      }
      if (input.tipo === "checkbox" || input.tipo === "switch") {
        return false;
      }
      return "";
    }
  };
  /**
   * Observar el cambio de los inputs selecionados y llamar a la función
   * callback en caso de ser declarada
   */
  useEffect(() => {
    if (onChanges && typeof onChanges === "function") {
      onChanges(watchAllFields, setValue);
    }
    return () => {};
    // eslint-disable-next-line
  }, [watchAllFields]);

  /**
   * Enviar la información al padre para su procesamiento
   * @param {object} data objecto que contiene la información contenida en los inputs
   * @version 1.0.2
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const onSubmit = (data) => {
    saveChanges(data);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} {...props}>
      <Grid container spacing={2}>
        {inputs.map((input) => {
          switch (input.tipo) {
            case "input":
            default:
              return (
                <Grid key={input.nombre} item {...input.grid}>
                  <TextField
                    id={input.nombre}
                    type="text"
                    name={input.nombre}
                    defaultValue={obtenerValor(input)}
                    label={
                      input.etiqueta === undefined
                        ? input.nombre
                        : typeof input.etiqueta === "function"
                        ? input.etiqueta()
                        : input.etiqueta
                    }
                    variant="filled"
                    inputRef={register(input.validacion)}
                    error={
                      Object.entries(errors).find(
                        ([key, value]) => key === input.nombre
                      )
                        ? true
                        : false
                    }
                    helperText={obtenerError(
                      Object.entries(errors).find(
                        ([key, value]) => key === input.nombre
                      )
                    )}
                    {...input.config}
                  />
                </Grid>
              );

            case "number":
              return (
                <Grid key={input.nombre} item {...input.grid}>
                  <TextField
                    id={input.nombre}
                    type={input.tipo}
                    name={input.nombre}
                    defaultValue={obtenerValor(input)}
                    label={
                      input.etiqueta === undefined
                        ? input.nombre
                        : typeof input.etiqueta === "function"
                        ? input.etiqueta()
                        : input.etiqueta
                    }
                    variant="filled"
                    inputRef={register(input.validacion)}
                    error={
                      Object.entries(errors).find(
                        ([key, value]) => key === input.nombre
                      )
                        ? true
                        : false
                    }
                    helperText={obtenerError(
                      Object.entries(errors).find(
                        ([key, value]) => key === input.nombre
                      )
                    )}
                    {...input.config}
                  />
                </Grid>
              );
            case "textarea":
              return (
                <Grid key={input.nombre} item {...input.grid}>
                  <TextField
                    multiline
                    id={input.nombre}
                    type="text"
                    name={input.nombre}
                    defaultValue={obtenerValor(input)}
                    label={
                      input.etiqueta === undefined
                        ? input.nombre
                        : typeof input.etiqueta === "function"
                        ? input.etiqueta()
                        : input.etiqueta
                    }
                    variant="filled"
                    inputRef={register(input.validacion)}
                    error={
                      Object.entries(errors).find(
                        ([key, value]) => key === input.nombre
                      )
                        ? true
                        : false
                    }
                    helperText={obtenerError(
                      Object.entries(errors).find(
                        ([key, value]) => key === input.nombre
                      )
                    )}
                    {...input.config}
                  />
                </Grid>
              );
            case "switch":
              return (
                <Grid key={input.nombre} item {...input.grid}>
                  <FormControlLabel
                    label={
                      input.etiqueta === undefined
                        ? input.nombre
                        : typeof input.etiqueta === "string"
                        ? input.etiqueta
                        : input.etiqueta()
                    }
                    control={
                      <Switch
                        id={input.nombre}
                        name={input.nombre}
                        inputRef={register}
                        defaultChecked={obtenerValor(input)}
                        {...input.config}
                      />
                    }
                  />
                </Grid>
              );
            case "checkbox":
              return (
                <Grid key={input.nombre} item {...input.grid}>
                  {input.opciones === undefined ? (
                    <FormControlLabel
                      inputRef={register}
                      label={
                        input.etiqueta === undefined
                          ? input.nombre
                          : typeof input.etiqueta === "string"
                          ? input.etiqueta
                          : input.etiqueta()
                      }
                      control={
                        <Checkbox
                          defaultChecked={obtenerValor(input)}
                          color="primary"
                          id={input.nombre}
                          name={input.nombre}
                        />
                      }
                    />
                  ) : (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        {input.etiqueta === undefined
                          ? input.nombre
                          : typeof input.etiqueta === "string"
                          ? input.etiqueta
                          : input.etiqueta()}
                      </FormLabel>
                      <FormGroup>
                        {input.opciones.map((opcion) => (
                          <FormControlLabel
                            key={opcion.label}
                            inputRef={register}
                            label={opcion.label}
                            control={
                              <Checkbox
                                defaultChecked={obtenerValor({
                                  nombre: opcion.value,
                                  tipo: "checkbox",
                                })}
                                color="primary"
                                id={opcion.value}
                                name={opcion.value}
                              />
                            }
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  )}
                </Grid>
              );
            case "array":
              return (
                <Grid key={input.nombre} item {...input.grid}>
                  <FormControl
                    id={input.nombre}
                    variant="filled"
                    error={errors[input.nombre] ? true : false}
                    {...input.config}
                  >
                    <InputLabel>
                      {input.etiqueta === undefined
                        ? input.nombre
                        : typeof input.etiqueta === "string"
                        ? input.etiqueta
                        : input.etiqueta()}
                    </InputLabel>
                    <Controller
                      id={input.nombre}
                      name={input.nombre}
                      control={control}
                      defaultValue={obtenerValor(input)}
                      multiple={input.multiple}
                      rules={input.validacion}
                      as={
                        <Select>
                          {!input.multiple && (
                            <MenuItem value="">Seleccionar</MenuItem>
                          )}
                          {input.opciones.map((opcion) => (
                            <MenuItem key={opcion.value} value={opcion.value}>
                              {opcion.label !== undefined
                                ? opcion.label
                                : opcion.value}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                    />
                    {errors[input.nombre] && (
                      <FormHelperText>
                        {errors[input.nombre].message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              );

            case "hidden":
              return (
                <Input
                  key={input.nombre}
                  id={input.nombre}
                  name={input.nombre}
                  defaultValue={obtenerValor(input)}
                  inputRef={register}
                  type="hidden"
                />
              );

            case "multiple":
              return (
                <MultiInputField
                  key={input.nombre}
                  nombre={input.nombre}
                  etiqueta={input.etiqueta}
                  inputs={input.inputs}
                  datos={obtenerValor(input)}
                  register={register}
                  errors={errors}
                />
              );
            case "editor":
              return (
                <Grid key={input.nombre} item {...input.grid}>
                  <Typography
                    component="div"
                    variant="h6"
                    color={errors[input.nombre] ? "error" : "inherit"}
                  >
                    {input.etiqueta === undefined
                      ? input.nombre
                      : typeof input.etiqueta === "string"
                      ? input.etiqueta
                      : input.etiqueta()}
                  </Typography>
                  <Controller
                    as={EditorField}
                    control={control}
                    name={input.nombre}
                    defaultValue={obtenerValor(input)}
                    rules={input.validacion}
                  />
                  {errors[input.nombre] && (
                    <Typography variant="caption" color="error">
                      {errors[input.nombre].message}
                    </Typography>
                  )}
                </Grid>
              );
          }
        })}
      </Grid>
      <Grid container justify="flex-end" spacing={2}>
        <Grid item>
          <Button
            type="button"
            onClick={onCancel}
            variant="contained"
            color="primary"
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit" variant="contained" color="secondary">
            {etiquetaBotonGuardado}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default Formulario;
