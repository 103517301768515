/**
 * @module Redux/Reducers
 */

/**
 * Estado inicial de dialogos
 */
const inicial = {
  dialog_introduccion: true,
  dialog_instruccion: true,
};
/**
 * Reducer usado por redux para manipular el visionado de dialogos
 * @param {object} state - Estado inicial
 * @param {object} action - parametros para realizar
 * @param {string} action.type la accion a realizar
 * @param {*=} action.payload datos para realizar la acción
 * 
 * @returns {object} - Estado modificado
 * 
 * @version 1.0.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
const dialogsReducer = (state = inicial, action) => {
  switch (action.type) {
    case "DIALOGS":
      return (state = action.payload);
    case "CHECK_INTRODUCCION":
      return (state = { ...state, dialog_introduccion: false });
    case "CHECK_INSTRUCCION":
      return (state = { ...state, dialog_instruccion: false });
    case "UNCHECK_INTRODUCCION":
      return (state = { ...state, dialog_introduccion: true });
    case "UNCHECK_INSTRUCCION":
      return (state = { ...state, dialog_instruccion: true });
    default:
      return state;
  }
};

export default dialogsReducer;
