/**
 * @module Redux/Actions
 */

/**
 * Asignar token
 * @param {string} token cadena de seguridad
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const setToken = (token) => {
  return {
    type: "SET_TOKEN",
    payload: token,
  };
};

/**
 * Eliminar el token
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const deleteToken = () => {
  return {
    type: "DELETE_TOKEN",
  };
};
/**
 * Guarda el usuario actual
 * @param {*} usuario
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const setUser = (user) => {
  return {
    type: "SET_USER",
    payload: user,
  };
};
/**
 * Elimina el usuario
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const deleteUser = () => {
  return {
    type: "DELETE_USER",
  };
};
/**
 * Guarda la ronda actual
 * @param {*} ronda
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const setRonda = (ronda) => {
  return {
    type: "SET_RONDA",
    payload: ronda,
  };
};
/**
 * Elimina la ronda actual
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const deleteRonda = () => {
  return {
    type: "DELETE_RONDA",
  };
};
/**
 * Guarda el ejercicio actual
 * @param {*} ronda
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const setEjercicio = (ejercicio) => {
  return {
    type: "SET_EJERCICIO",
    payload: ejercicio,
  };
};
/**
 * Elimina el ejercicio actual
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const deleteEjercicio = () => {
  return {
    type: "DELETE_EJERCICIO",
  };
};
/**
 * Asigna todas las rondas disponibles
 * @param {*} rondas
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const setRondas = (rondas) => {
  return {
    type: "SET_RONDAS",
    payload: rondas,
  };
};
/**
 * Elimina todas las rondas disponibles
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const deleteRondas = () => {
  return {
    type: "DELETE_RONDAS",
  };
};
/**
 * loguear al usuario
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const loginIn = () => {
  return {
    type: "LOG_IN",
  };
};
/**
 * Desloguear al usuario
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const loginOut = () => {
  return {
    type: "LOG_OUT",
  };
};
/**
 * Asigna el estado de admin del usuario
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const isAdmin = (bool) => {
  return {
    type: "ADMIN",
    payload: bool,
  };
};
/**
 * Asigna el visionado de los dialogos
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const checkDialogs = (dialgos_check) => {
  return {
    type: "DIALOGS",
    payload: dialgos_check,
  };
};
/**
 * Marca como visto el dialogo de <Introduccion/>
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const checkDialogIntroduccion = () => {
  return {
    type: "CHECK_INTRODUCCION",
  };
};
/**
 * Marca como visto el dialogo de <Instruccion/>
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const checkDialogInstruccion = () => {
  return {
    type: "CHECK_INSTRUCCION",
  };
};
/**
 * Marca como no visto el dialogo de Introducción
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const uncheckDialogIntroduccion = () => {
  return {
    type: "UNCHECK_INTRODUCCION",
  };
};
/**
 * Marca como no visto el dialogo de Instruccion
 * @version 1.0.0
 * @author Ing. Roberto Alonso de la Garza Mendoza
 */
const uncheckDialogInstruccion = () => {
  return {
    type: "UNCHECK_INSTRUCCION",
  };
};

export {
  setToken,
  deleteToken,
  setUser,
  deleteUser,
  setRonda,
  deleteRonda,
  setEjercicio,
  deleteEjercicio,
  setRondas,
  deleteRondas,
  loginIn,
  loginOut,
  isAdmin,
  checkDialogs,
  checkDialogIntroduccion,
  checkDialogInstruccion,
  uncheckDialogIntroduccion,
  uncheckDialogInstruccion
};
