import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../Helpers/PrivateRoute";
import Menu from "./Menu/Menu";
import LoginAdmin from "./LoginAdmin/LoginAdmin";
import AdminContainer from "../Helpers/AdminContainer";
import { useSelector } from "react-redux";
import Administrador from "./Administrador/Administrador";
import Dashboard from "./Dashboard/Dashboard";
import Ejercicio from "./Ejercicio/Ejercicio";
import Aspiracion from "./Aspiracion/Aspiracion";
import LineaEstrategica from "./LineaEstrategica/LineaEstrategica";
import Objetivo from "./Objetivo/Objetivo";
import Usuario from "./Usuario/Usuario";
import Ronda from "./Ronda/Ronda";
import Indicador from "./Indicador/Indicador";
/**
 * Componente principal y enrutador del area de administración
 * 
 * @category Administración
 * @version 1.0.10
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Admin() {
  let { path } = useRouteMatch();
  /**
   * Selector de Redux que obtiene el usuario logueado
   */
  const isAdmin = useSelector((state) => state.isAdmin);
  return (
    <div>
      {isAdmin ? <Menu /> : null}
      <Switch>
        <Route exact path={path}>
          <LoginAdmin />
        </Route>
        <PrivateRoute
          auth={isAdmin}
          redirect={path}
          path={`${path}/administradores`}
        >
          <AdminContainer>
            <Administrador />
          </AdminContainer>
        </PrivateRoute>
        <PrivateRoute auth={isAdmin} redirect={path} path={`${path}/dashboard`}>
          <AdminContainer>
            <Dashboard />
          </AdminContainer>
        </PrivateRoute>
        <PrivateRoute
          auth={isAdmin}
          redirect={path}
          path={`${path}/ejercicios`}
        >
          <AdminContainer>
            <Ejercicio />
          </AdminContainer>
        </PrivateRoute>
        <PrivateRoute
          auth={isAdmin}
          redirect={path}
          path={`${path}/aspiraciones`}
        >
          <AdminContainer>
            <Aspiracion />
          </AdminContainer>
        </PrivateRoute>
        <PrivateRoute auth={isAdmin} redirect={path} path={`${path}/objetivos`}>
          <AdminContainer>
            <Objetivo />
          </AdminContainer>
        </PrivateRoute>
        <PrivateRoute
          auth={isAdmin}
          redirect={path}
          path={`${path}/lineas-estrategicas`}
        >
          <AdminContainer>
            <LineaEstrategica />
          </AdminContainer>
        </PrivateRoute>
        <PrivateRoute auth={isAdmin} redirect={path} path={`${path}/usuarios`}>
          <AdminContainer>
            <Usuario />
          </AdminContainer>
        </PrivateRoute>
        <PrivateRoute auth={isAdmin} redirect={path} path={`${path}/rondas`}>
          <AdminContainer>
            <Ronda />
          </AdminContainer>
        </PrivateRoute>
        <PrivateRoute
          auth={isAdmin}
          redirect={path}
          path={`${path}/indicadores`}
        >
          <AdminContainer>
            <Indicador />
          </AdminContainer>
        </PrivateRoute>
        <PrivateRoute auth={isAdmin} redirect={path} path={`${path}/*`} />
      </Switch>
    </div>
  );
}

export default Admin;
