import { Typography } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
/**
 * Componente que muestra el nombre del usuario logueado
 * 
 * @category Votación
 * @version 1.1.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
export function UserHeader({ user }) {
  return (
    <div className="panel_usuario_informacion">
      <div className="informacion">
        <Typography variant="h4" className="usuario_informacion">{`Bienvenid${
          user.sexo ? "a" : "o"
        }`}</Typography>
        <Typography variant="h4" className="usuario_informacion nombre">
          {user.nombre}
        </Typography>
        {/* <h2 className="usuario_informacion puesto"> {user.puesto}</h2> */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(UserHeader);
