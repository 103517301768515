import { Button, Divider, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Panel from "./Panel";
import "./Resultados.css";
import Resultado from "./Resultado/Resultado";
import Skeleton from "../Helpers/Skeleton";
import Api from "../../Restful/Api";
import { Link, useParams } from "react-router-dom";
/**
 * Componente que muestra una lista de resultados usando el componente Resultado
 *
 * @category Votación
 * @version 1.0.3
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Resultados() {
  /**
   * Hook para manipular la bandera de carga
   */
  const [loading, setLoading] = useState(true);
  /**
   * Hook para manipular los elementos a mostrar (aspiraciones)
   */
  const [aspiraciones, setAspiraciones] = useState([]);

  /**
   * Hook para manipular los elementos a mostrar (objetivos)
   */
  const [objetivos, setObjetivos] = useState([]);
  /**
   * El id de la ronda actual
   */
  let { id } = useParams();
  /**
   * Arreglo de cadenas para mandar como encabezados
   */
  const encabezados = ["INDICADOR", "SI", "NO", "PREFIERE NO RESPONDER"];

  /**
   * Consumo de la api al terminar de cargar el componente
   */
  useEffect(() => {
    Api.get(`/ronda/${id}/resultados`)
      .then((response) => {
        setLoading(false);
        switch (response.status) {
          case 200:
            setAspiraciones(response.data.aspiraciones);
            setObjetivos(response.data.objetivosLineas);
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          console.log(error.response.data);
          //showSnackbar(error.response.data.mesage);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error>>>", error.message);
          //showSnackbar(error.message);
        }
      });
  }, [id]);

  return (
    <Panel>
      <Link to="/rondas">
        <Button variant="contained" color="primary">
          Regresar
        </Button>
      </Link>
      <Typography variant="h4" align="center" gutterBottom>
        Resultados
      </Typography>
      <div className="ResultadosPanel">
        <Typography variant="h5" gutterBottom>
          Aspiraciones
        </Typography>
        <Divider />
        {loading ? (
          <Skeleton count={4} />
        ) : (
          aspiraciones?.map((aspiracion) => (
            <Resultado
              key={aspiracion.id}
              nombre={aspiracion.descripcion}
              encabezados={encabezados}
              indicadores={aspiracion.indicadores}
            />
          ))
        )}
        <Typography variant="h5" gutterBottom>
          Objetivos y líneas estratégicas
        </Typography>
        <Divider />
        {loading ? (
          <Skeleton count={4} />
        ) : (
          objetivos?.map((objetivo) => {
            let sub = objetivo.lineasEstrategicas !== undefined &&
              objetivo.lineasEstrategicas.length > 0 && (
                <>
                  <Typography variant="h6" gutterBottom>
                    Líneas estratégicas
                  </Typography>
                  <Divider />
                  {objetivo.lineasEstrategicas.map((linea) => (
                    <div key={linea.id} className="resultado--subfila">
                      <Resultado
                        key={linea.id}
                        nombre={linea.descripcion}
                        encabezados={encabezados}
                        indicadores={linea.indicadores}
                      />
                    </div>
                  ))}
                </>
              );
            return (
              <div key={objetivo.id}>
                <Resultado
                  nombre={objetivo.descripcion}
                  encabezados={encabezados}
                  indicadores={objetivo.indicadores}
                />
                {sub}
              </div>
            );
          })
        )}
      </div>
    </Panel>
  );
}

export default Resultados;
