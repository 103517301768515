import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Api from "../../../Restful/Api";
import Crud from "../CRUD/Crud";
import SelectorEjercicio from "../selectorEjercicio/SelectorEjercicio";

/**
 * Componente que muestra un formulario para manipular aspiraciones
 *
 * @version 1.2.5
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Indicador({ ejercicioIds }) {
  /**
   * campo memorizado de ids de ejercicio
   */
  const permanentFilters = useMemo(() => {
    if (ejercicioIds) {
      return { ejercicioIds: [ejercicioIds] };
    } else {
      return {};
    }
  }, [ejercicioIds]);

  /**
   * Hook para manipular aspiraciones
   */
  const [aspiracionId, setAspiracionId] = useState("");
  /**
   * Hook para manipular objetivos
   */
  const [objetivoId, setObjetivoId] = useState("");
  /**
   * Hook para manipular lineas estrategicas
   */
  const [lineaEstrategicaId, setLineaEstrategicaId] = useState("");
  /**
   * Hook para manipular los ejercicios
   */
  const [ejercicios, setEjercicios] = useState([]);
  /**
   * Hook para manipular los inputs del formulario
   */
  const [inputs, setInputs] = useState([
    { nombre: "id", tipo: "hidden" },
    {
      nombre: "nombre",
      etiqueta: "Nombre",
      validacion: {
        required: "El campo es requerido",
        maxLength: {
          value: 250,
          message: "El nombre no puede ser mayor a 250 caracteres",
        },
      },
      config: { fullWidth: true, required: true },
      grid: { xs: 12, md: 12 },
    },
    {
      nombre: "definicion",
      etiqueta: "Definición",
      tipo: "textarea",
      validacion: {
        required: "El campo es requerido",
        maxLength: {
          value: 500,
          message: "La definición no puede ser mayor a 500 caracteres",
        },
      },
      config: { fullWidth: true, required: true, rows: 3 },
      grid: { xs: 12, md: 12 },
    },
    {
      nombre: "texto_explicativo",
      etiqueta: "Texto explicativo",
      tipo: "textarea",
      validacion: {
        maxLength: {
          value: 500,
          message: "El texto explicativo no puede ser mayor a 500 caracteres",
        },
      },
      config: { fullWidth: true, rows: 3 },
      grid: { xs: 12, md: 12 },
    },
    {
      nombre: "unidad_medida",
      etiqueta: "Unidad de medida",
      validacion: {
        required: "El campo es requerido",
        maxLength: {
          value: 100,
          message: "La unidad de medida no puede ser mayor a 100 caracteres",
        },
      },
      config: { fullWidth: true, required: true },
      grid: { xs: 12, md: 6 },
    },
    {
      nombre: "fuente",
      etiqueta: "Fuente",
      validacion: {
        required: "El campo es requerido",
        maxLength: {
          value: 300,
          message: "La fuente no puede ser mayor a 300 caracteres",
        },
      },
      config: { fullWidth: true, required: true },
      grid: { xs: 12, md: 6 },
    },
    {
      nombre: "url",
      etiqueta: "Url",
      validacion: {
        maxLength: {
          value: 500,
          message: "La url no puede ser mayor a 500 caracteres",
        },
      },
      config: { fullWidth: true },
      grid: { xs: 12, md: 12 },
    },
    {
      nombre: "rondaId",
      etiqueta: "Rondas",
      tipo: "array",
      multiple: true,
      opciones: [],
      validacion: {
        validate: {
          minimo: (valor) =>
            valor.length > 0 || "Se debe seleccionar al menos una ronda",
          maximo: (valor) =>
            valor.length < 4 || "No se puede seleccionar más de 3 rondas",
        },
      },
      config: { fullWidth: true, required: true },
      grid: { xs: 12 },
    },
    {
      nombre: "aspiracionId",
      etiqueta: "Aspiracion",
      tipo: "array",
      opciones: [],
      config: { fullWidth: true },
      grid: { xs: 12 },
    },
    {
      nombre: "objetivoId",
      etiqueta: "Objetivo",
      tipo: "array",
      opciones: [],
      config: { fullWidth: true },
      grid: { xs: 12 },
    },
    {
      nombre: "lineaEstrategicaId",
      etiqueta: "Línea Estrategica",
      tipo: "array",
      opciones: [],
      config: { fullWidth: true },
      grid: { xs: 12 },
    },
    {
      nombre: "imagenes",
      etiqueta: "Imagenes",
      tipo: "multiple",
      inputs: [
        {
          nombre: "descripcion",
          etiqueta: "Descripción",
          validacion: {
            maxLength: {
              value: 40,
              message: "La descripción no puede ser mayor a 40 caracteres",
            },
          },
        },
        {
          nombre: "url",
          etiqueta: "Dirección del archivo (url)",
          validacion: {
            maxLength: {
              value: 250,
              message: "La dirección no puede ser mayor a 250 caracteres",
            },
          },
          grid: { xs: 7 },
        },
      ],
    },
  ]);
  /**
   * Hook para manipular las rondas
   */
  const [rondas, setRondas] = useState([]);
  /**
   * Hook para manipular las aspiraciones
   */
  const [aspiraciones, setAspiraciones] = useState([]);
  /**
   * Hook para manipular los objetivos
   */
  const [objetivos, setObjetivos] = useState([]);
  /**
   * Hook para manipular las líneas estrategicas
   */
  const [lineasEstrategicas, setLineaEstrategicas] = useState([]);

  /**
   * Petición restful para obtener las rondas del servidor
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const getRondas = () => {
    return Api.get("api/ronda", {
      params: { size: 10000, ejercicioIds: permanentFilters.ejercicioIds },
    });
  };

  /**
   * Petición restful para obtener las aspiraciones del servidor
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const getAspiraciones = () => {
    return Api.get("api/Aspiracion", {
      params: { size: 10000, ejercicioIds: permanentFilters.ejercicioIds },
    });
  };

  /**
   * Petición restful para obtener los objetivos del servidor
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const getObjetivos = () => {
    return Api.get("api/Objetivo", {
      params: { size: 10000, ejercicioIds: permanentFilters.ejercicioIds },
    });
  };

  /**
   * Petición restful para obtener las líneas estrategicas del servidor
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const getLineasEstregicas = () => {
    return Api.get("api/lineaEstrategica", {
      params: { size: 10000, ejercicioIds: permanentFilters.ejercicioIds },
    });
  };

  /**
   * Hook para obtener los rondas, aspiraciones, objetivos y líneas estrategicas
   */
  useEffect(() => {
    Promise.all([
      getRondas(),
      getAspiraciones(),
      getObjetivos(),
      getLineasEstregicas(),
    ]).then((results) => {
      setRondas(
        results[0].data.data.map((ronda) => {
          return { value: ronda.id, label: ronda.descripcion };
        })
      );

      setAspiraciones(
        results[1].data.data.map((aspiracion) => {
          return {
            value: aspiracion.id,
            label: aspiracion.descripcion,
          };
        })
      );

      setObjetivos(
        results[2].data.data.map((objetivo) => {
          return {
            value: objetivo.id,
            label: objetivo.descripcion,
          };
        })
      );

      setLineaEstrategicas(
        results[3].data.data.map((lineaEstrategica) => {
          return {
            value: lineaEstrategica.id,
            label: lineaEstrategica.descripcion,
          };
        })
      );
    });
    return () => {};
    // eslint-disable-next-line
  }, [permanentFilters]);

  /**
   * Hook para agregar las rondas, aspiraciones, objetivos y lineas estrategicas al formulario
   */
  useEffect(() => {
    setInputs((inputs) =>
      inputs.map((input) => {
        switch (input.nombre) {
          case "rondaId":
            return {
              ...input,
              opciones: rondas,
            };
          case "aspiracionId":
            return {
              ...input,
              opciones: aspiraciones,
            };
          case "objetivoId":
            return {
              ...input,
              opciones: objetivos,
            };
          case "lineaEstrategicaId":
            return {
              ...input,
              opciones: lineasEstrategicas,
            };
          default:
            return input;
        }
      })
    );
    return () => {};
  }, [rondas, aspiraciones, objetivos, lineasEstrategicas]);

  /**
   * Obtener los ejercios para filtrado y formulario haciendo uso del endpoint findAll
   * seteando el param size a 1000 para evitar paginado
   */
  useEffect(() => {
    Api.get("api/ejercicio", { params: { size: 1000 } }).then((response) => {
      setEjercicios(
        response.data.data.map((ejercicio) => {
          return { value: ejercicio.id, label: ejercicio.nombre };
        })
      );
    });
    return () => {};
  }, []);

  /**
   * Manipular el objecto para concidir con la especificación del servidor
   *
   * @param {object} data la información a guardar
   *
   * @version 1.0.1
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const onSave = (data) => {
    const {
      id,
      rondaId,
      imagenes = [],
      texto_explicativo,
      unidad_medida,
      ...inputs
    } = data;

    if (data.aspiracionId === "") {
      delete inputs.aspiracionId;
    }

    if (data.objetivoId === "") {
      delete inputs.objetivoId;
    }

    if (data.lineaEstrategicaId === "") {
      delete inputs.lineaEstrategicaId;
    }

    const imagenesFiltradas = imagenes.filter(
      (imagen) => imagen.descripcion && imagen.url
    );

    return {
      indicador: {
        ...inputs,
        textoExplicativo: texto_explicativo,
        unidadMedida: unidad_medida,
      },
      rondas: rondaId,
      imagenes: imagenesFiltradas,
    };
  };

  /**
   * Manipular el objecto del servidor para concidir con la especificación del formulario
   *
   * @param {object} data la información del servidor
   *
   * @version 1.1.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const onEdit = (data) => {
    const {
      rondas,
      images,
      aspiracion,
      objetivo,
      linea_estrategica,
      ...inputs
    } = data;
    const rondasId = rondas.map((ronda) => ronda.id);

    const aspiracionId =
      aspiracion === null
        ? ""
        : aspiraciones.find((asp) => asp.label === aspiracion?.descripcion)
            ?.value;
    setAspiracionId(aspiracionId);
    const objetivoId =
      objetivo === null
        ? ""
        : objetivos.find((obj) => obj.label === objetivo?.descripcion)?.value;
    setObjetivoId(objetivoId);
    const lineaEstrategicaId =
      linea_estrategica === null
        ? ""
        : lineasEstrategicas.find(
            (le) => le.label === linea_estrategica?.descripcion
          )?.value;
    setLineaEstrategicaId(lineaEstrategicaId);
    return {
      ...inputs,
      rondaId: rondasId,
      aspiracionId,
      objetivoId,
      lineaEstrategicaId,
      imagenes: images,
    };
  };

  /**
   * Cambia las llaves del objeto para coincidir con el servidor
   *
   * @param {object} filtros
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleFilters = (filtros) => {
    const { texto_explicativo, unidad_medida, ...otrosFiltros } = filtros;
    return {
      ...otrosFiltros,
      textoExplicativo: texto_explicativo,
      unidadMedida: unidad_medida,
    };
  };

  /**
   * Permite hacer modificaciones en los datos del formulario dependiende del cambio
   * de los mismos
   *
   * @param {string|object} inputs el los inputs a observar
   * @param {function} setValue función que permite cambiar datos del formulario
   *
   * @version 1.0.1
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const onChanges = (inputs, setValue) => {
    if (
      inputs.aspiracionId !== undefined &&
      inputs.aspiracionId !== "" &&
      inputs.aspiracionId !== aspiracionId
    ) {
      setAspiracionId(inputs.aspiracionId);
      setObjetivoId("");
      setLineaEstrategicaId("");
      setValue("objetivoId", "");
      setValue("lineaEstrategicaId", "");
    }
    if (
      inputs.objetivoId !== undefined &&
      inputs.objetivoId !== "" &&
      inputs.objetivoId !== objetivoId
    ) {
      setObjetivoId(inputs.objetivoId);
      setAspiracionId("");
      setLineaEstrategicaId("");
      setValue("aspiracionId", "");
      setValue("lineaEstrategicaId", "");
    }
    if (
      inputs.lineaEstrategicaId !== undefined &&
      inputs.lineaEstrategicaId !== "" &&
      inputs.lineaEstrategicaId !== lineaEstrategicaId
    ) {
      setLineaEstrategicaId(inputs.lineaEstrategicaId);
      setAspiracionId("");
      setObjetivoId("");
      setValue("aspiracionId", "");
      setValue("objetivoId", "");
    }
  };

  return (
    <>
      <SelectorEjercicio ejercicios={ejercicios} />
      <Crud
        nombre="Indicadores"
        findAllUrl="api/indicador"
        findByIdUrl="api/indicador/"
        createUrl="api/indicador/"
        updateUrl="api/indicador/"
        deleteUrl="api/indicador/"
        permanentFilters={permanentFilters}
        inputs={inputs}
        columnas={[
          {
            nombre: "nombre",
            etiqueta: "Nombre",
            config: { fullWidth: true },
            grid: { xs: 12, md: 6 },
          },
          {
            nombre: "definicion",
            etiqueta: "Definición",
            config: { fullWidth: true },
            grid: { xs: 12, md: 6 },
          },
          {
            nombre: "texto_explicativo",
            etiqueta: "Texto explicativo",
            config: { fullWidth: true },
            grid: { xs: 12, md: 6 },
          },
          {
            nombre: "fuente",
            etiqueta: "Fuente",
            config: { fullWidth: true },
            grid: { xs: 12, md: 6 },
          },
          {
            nombre: "unidad_medida",
            etiqueta: "Unidad de medida",
            config: { fullWidth: true },
            grid: { xs: 12, md: 6 },
          },
          {
            nombre: "url",
            etiqueta: "Url",
            config: { fullWidth: true },
            grid: { xs: 12, md: 6 },
          },
          {
            nombre: "rondaId",
            etiqueta: "Ronda",
            tipo: "array",
            opciones: rondas,
            config: { fullWidth: true },
            grid: { xs: 12 },
            onlyFilter: true,
          },
          {
            nombre: "aspiracionId",
            etiqueta: "Aspiracion",
            tipo: "array",
            opciones: aspiraciones,
            config: { fullWidth: true },
            grid: { xs: 12 },
            onlyFilter: true,
          },
          {
            nombre: "objetivoId",
            etiqueta: "Objetivo",
            tipo: "array",
            opciones: objetivos,
            config: { fullWidth: true },
            grid: { xs: 12 },
            onlyFilter: true,
          },
          {
            nombre: "lineaEstrategicaId",
            etiqueta: "Línea Estrategica",
            tipo: "array",
            opciones: lineasEstrategicas,
            config: { fullWidth: true },
            grid: { xs: 12 },
            onlyFilter: true,
          },
        ]}
        onFilter={handleFilters}
        onSave={onSave}
        onEdit={onEdit}
        FormularioProps={{
          watchedInputs: ["aspiracionId", "objetivoId", "lineaEstrategicaId"],
          onChanges: onChanges,
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => ({ ejercicioIds: state.ejercicio.id });

export default connect(mapStateToProps)(Indicador);
