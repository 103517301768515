import React, { useState } from "react";
import "./Login.css";
import Api, { handleError, setAuthToken } from "../../Restful/Api";
import { Button, Snackbar, TextField, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AvisoPrivacidad from "../Dialogs/AvisoPrivacidad/AvisoPrivacidad";

import { connect } from "react-redux";
import { loginIn, setToken, setUser } from "../../Redux/Actions";
import LoadingButton from "../Helpers/LoadingButton/LoadingButton";
import { useForm } from "react-hook-form";
/**
 * Ventana de login para usuarios participantes
 *
 * @category Votación
 * @version 1.2.1
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
export function Login({ logInUser }) {
  /**Hook para estado de carga */
  const [loading, setLoading] = useState(false);
  /**Hook para control del form */
  const { handleSubmit, register, errors } = useForm();
  /**Hook para mostrar Snackbar*/
  const [message_snackbar, setMessageSnackbar] = useState(false);
  /**Hook para mensage de error */
  const [message, setMessage] = useState("");
  /**Hook para mostrar aviso de privacidad */
  const [showDialog, setShowDialog] = useState(false);

  let history = useHistory();
  /**
   * mostrar un mensaje en el fondo de la pantalla
   *
   * @param {string} message - El mensaje a mostrar
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const showSnackbar = (message) => {
    setMessage(message);
    setMessageSnackbar(true);
  };
  /**
   * Funcion a validar formulario y consumir API
   *
   * @param {Object} data - Información del formulario una vez ha sido validada
   *
   * @version 1.0.2
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const onSubmit = (data) => {
    setLoading(true);
    Api.post("/login", data)
      .then((response) => {
        setLoading(false);
        switch (response.status) {
          case 200:
            setAuthToken(response.data.token);
            logInUser(response.data);
            history.push("/Ejercicios");
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        setLoading(false);
        showSnackbar(handleError(error));
      });
  };
  return (
    <div className="Login">
      <div className="Login_logo_section">
        <img
          alt="logo_nuevo_leon"
          className="Login_logo"
          src="https://delphiconl.s3.us-east-2.amazonaws.com/front/nuevo_leon_logo.svg"
        ></img>
      </div>
      <div className="Login_Headlines">
        <Typography variant="h3">
          Plataforma participativa y de inteligencia colectiva
        </Typography>
        <Typography variant="h4">
          Ingrese a la plataforma con su correo electrónico registrado
        </Typography>
        <form
          className="Login_form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            name="email"
            defaultValue=""
            type="text"
            label="Email"
            variant="filled"
            color="primary"
            required
            autoFocus
            inputRef={register({ required: "El campo es requerido" })}
            error={errors?.email ? true : false}
            helperText={errors?.email?.message}
          />
          <LoadingButton
            className="Login_button"
            loading={loading}
            text="Ingresar"
            loading_text="Cargando"
            type="submit"
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disableElevation
          />
        </form>
        <div className="bottom_button">
          <Button
            color="primary"
            variant="text"
            onClick={() => setShowDialog(true)}
          >
            Aviso de privacidad
          </Button>
        </div>
      </div>
      {/* Snackbar section */}
      <Snackbar
        message={message}
        open={message_snackbar}
        autoHideDuration={5000}
        onClose={() => {
          setMessageSnackbar(false);
        }}
      />
      {/* Dialog */}
      <AvisoPrivacidad
        flagOpen={showDialog}
        handleClose={() => setShowDialog(false)}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    logInUser: (data) => {
      dispatch(setToken(data.token));
      dispatch(setUser(data.usuario));
      dispatch(loginIn());
    },
  };
};

export default connect(null, mapDispatchToProps)(Login);
