import React, { useState } from "react";
import "./LoginAdmin.css";
import {
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import LoadingButton from "../../Helpers/LoadingButton/LoadingButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { isAdmin, setUser, loginIn } from "../../../Redux/Actions";
import Api, { handleError, setAuthToken } from "../../../Restful/Api";
/**
 * Login para sección de administración
 *
 * @category Administración
 * @version 1.0.4
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function LoginAdmin() {
  /**
   * Hook para la manipulación de direccion en el navegador
   */
  const history = useHistory();
  /**
   * ruta actual
   */
  const { path } = useRouteMatch();
  /**
   * hook para mensaje
   */
  const [mensage, setMensage] = useState("");
  /**
   * hook para mostrar snackbar
   */
  const [showSnackbar, setShowSnackbar] = useState(false);

  /**
   * Hook para mostrar la contraseña
   */
  const [showPassword, setShowPassword] = useState(false);
  /**
   * Hook para manipular el estado de carga
   */
  const [loading, setLoading] = useState(false);
  /**
   * Hook para validación de formulario
   */
  const { handleSubmit, control, errors } = useForm();
  /**
   * Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Validación de formulario y consumo de API
   * @version 1.0.0
   */
  const onSubmit = (data) => {
    setLoading(true);
    Api.post("/api/auth/login", data)
      .then((response) => {
        dispatch(setUser(response.data.usuario));
        dispatch(isAdmin(true));
        dispatch(loginIn());
        setAuthToken(response.data.token);
        history.push(`${path}/ejercicios`);
      })
      .catch((error) => {
        setLoading(false);
        setShowSnackbar(true);
        setMensage(handleError(error));
      });
  };

  return (
    <div className="LoginAdmin">
      <img
        alt="logo_nuevo_leon"
        className="Login_logo"
        src="https://delphiconl.s3.us-east-2.amazonaws.com/front/nuevo_leon_logo.svg"
      ></img>
      <Typography variant="h3" gutterBottom>
        Plataforma participativa y de inteligencia colectiva
      </Typography>
      <Typography variant="h4" gutterBottom>
        Administración
      </Typography>
      <form
        className="form_login_admin"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          as={TextField}
          id="usuario"
          name="usuario"
          control={control}
          defaultValue=""
          type="text"
          label="usuario"
          variant="filled"
          autoFocus
          fullWidth
          color="primary"
          required
          error={errors?.usuario}
          helperText={errors?.usuario?.message}
          rules={{ required: "Usuario es requerido" }}
        />
        <FormControl
          variant="filled"
          fullWidth
          required
          name="contrasena"
          error={errors?.contrasena}
        >
          <InputLabel htmlFor="contrasena">contraseña</InputLabel>
          <Controller
            control={control}
            name="contrasena"
            rules={{ required: "Contraseña es requerido" }}
            defaultValue=""
            render={({ onChange, value, name }) => (
              <FilledInput
                id="contrasena"
                name={name}
                type={showPassword ? "text" : "password"}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Mostrar contraseña"
                      onClick={() =>
                        setShowPassword((prev) => {
                          return !prev;
                        })
                      }
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          />
          <FormHelperText id="helper-text-password">
            {errors?.contrasena?.message}
          </FormHelperText>
        </FormControl>
        <LoadingButton
          loading={loading}
          text="Ingresar"
          loading_text="Cargando"
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
        />
      </form>

      {/* Snackbar para mostrar mensajes */}
      <Snackbar
        open={showSnackbar}
        message={mensage}
        autoHideDuration={2000}
        onClose={() => setShowSnackbar(false)}
      />
    </div>
  );
}

export default LoginAdmin;
