import React from 'react';
import Crud from '../CRUD/Crud';
import { format, parse } from 'date-fns';
/**
 * Componente que muestra un formulario para manipular ejercicios
 *
 * @version 1.0.5
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Ejercicio() {
  /**
   * Hook para manipular los inputs del formulario
   */
  const inputs = [
    {
      nombre: 'id',
      tipo: 'hidden',
    },
    {
      nombre: 'nombre',
      etiqueta: 'Nombre',
      tipo: 'text',
      validacion: { required: 'El campo es requerido' },
      config: {
        required: true,
        fullWidth: true,
      },
      grid: {
        xs: 12,
      },
    },
    {
      nombre: 'descripcion',
      etiqueta: 'Descripción',
      tipo: 'textarea',
      validacion: { required: 'El campo es requerido' },
      config: { required: true, fullWidth: true, rows: 4 },
      grid: {
        xs: 12,
      },
    },
    {
      nombre: 'fecha_inicio',
      etiqueta: 'Fecha inicio',
      tipo: 'text',
      validacion: { required: 'El campo es requerido' },
      config: {
        fullWidth: true,
        type: 'date',
      },
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      nombre: 'fecha_fin',
      etiqueta: 'Fecha fin',
      tipo: 'text',
      validacion: { required: 'El campo es requerido' },
      config: {
        fullWidth: true,
        type: 'date',
      },
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      nombre: 'instrucciones',
      etiqueta: 'Instrucciones a mostrar',
      tipo: 'editor',
      validacion: { required: 'El campo es requerido' },
      grid: {
        xs: 12,
        md: 12,
      },
    },
    {
      nombre: 'estatus',
      etiqueta: (valor) => {
        if (valor !== undefined) return valor ? 'Activo' : 'Inactivo';
        return 'Estatus';
      },
      tipo: 'switch',
    },
    {
      nombre: 'nombreCategoriaA',
      etiqueta: 'Alias de Aspiraciones',
      tipo: 'text',
      validacion: { required: 'El campo es requerido' },
      config: {
        required: true,
        fullWidth: true,
      },
      grid: {
        xs: 12,
      },
    },
    {
      nombre: 'nombreCategoriaB',
      etiqueta: 'Alias de Objetivos',
      tipo: 'text',
      validacion: { required: 'El campo es requerido' },
      config: {
        required: true,
        fullWidth: true,
      },
      grid: {
        xs: 12,
      },
    },
    {
      nombre: 'nombreSubCategoriaB1',
      etiqueta: 'Alias de Líneas estrategicas',
      tipo: 'text',
      validacion: { required: 'El campo es requerido' },
      config: {
        required: true,
        fullWidth: true,
      },
      grid: {
        xs: 12,
      },
    },
    {
      nombre: 'archivos',
      etiqueta: 'Documentos de guía',
      tipo: 'multiple',
      inputs: [
        {
          nombre: 'orden',
          etiqueta: 'Orden',
          config: {
            type: 'number',
          },
        },
        {
          nombre: 'descripcion',
          etiqueta: 'Descripción',
          validacion: {
            maxLength: {
              value: 40,
              message: 'La descripción no puede ser mayor a 40 caracteres',
            },
          },
        },
        {
          nombre: 'url',
          etiqueta: 'Dirección del archivo (url)',
          validacion: {
            maxLength: {
              value: 249,
              message: 'La dirección no puede ser mayor a 40 caracteres',
            },
          },
          grid: { xs: 7 },
        },
      ],
    },
  ];
  /**
   * Manipular el objecto para concidir con la especificación del servidor
   *
   * @param {object} data Los datos a enviar al servidor
   *
   * @version 1.0.2
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const onSave = (data) => {
    const { archivos = [], id, ...inputs } = data;
    const archivosFiltrados = archivos.filter(
      (archivo) => archivo.descripcion && archivo.url && archivo.orden
    );

    return {
      ejercicio: {
        ...inputs,
        fecha_inicio: parse(
          inputs.fecha_inicio,
          'yyyy-MM-dd',
          new Date()
        ).toISOString(),
        fecha_fin: parse(
          inputs.fecha_fin,
          'yyyy-MM-dd',
          new Date()
        ).toISOString(),
      },
      archivos: archivosFiltrados,
    };
  };
  /**
   * Da formato a las fechas
   * @param {object} data El registro del servidor
   *
   * @version 1.0.1
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const onEdit = (data) => {
    const { fecha_inicio, fecha_fin, ...inputs } = data;
    return {
      ...inputs,
      fecha_inicio: format(new Date(data.fecha_inicio), 'yyyy-MM-dd'),
      fecha_fin: format(new Date(data.fecha_fin), 'yyyy-MM-dd'),
    };
  };
  /**
   * Cambia las llaves del objecto para coincidir con el servidor
   *
   * @param {object} filtros un objecto con los filtros
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleFilters = (filtros) => {
    const { fecha_inicio, ...otrosfiltros } = filtros;

    const fecha_ini = fecha_inicio;
    return { ...otrosfiltros, fecha_ini };
  };

  return (
    <Crud
      nombre="Ejercicios"
      inputs={inputs}
      findAllUrl="api/ejercicio"
      findByIdUrl="api/ejercicio/"
      createUrl="api/ejercicio"
      updateUrl="api/ejercicio/"
      deleteUrl="api/ejercicio/"
      columnas={[
        {
          nombre: 'nombre',
          etiqueta: 'Nombre',
        },
        { nombre: 'descripcion', etiqueta: 'Descripción' },
        {
          nombre: 'fecha_inicio',
          etiqueta: (valor) => {
            if (valor === undefined) {
              return 'Fecha inicio';
            } else {
              return format(new Date(valor), 'dd-MM-yyyy');
            }
          },
          config: {
            type: 'date',
          },
        },
        {
          nombre: 'fecha_fin',
          etiqueta: (valor) => {
            if (valor === undefined) {
              return 'Fecha fin';
            } else {
              return format(new Date(valor), 'dd-MM-yyyy');
            }
          },
          config: {
            type: 'date',
          },
        },
        {
          nombre: 'estatus',
          etiqueta: (valor) => {
            if (valor !== undefined) return valor ? 'Activo' : 'Inactivo';
            return 'Estatus';
          },
          tipo: 'switch',
          config: {
            defaultChecked: true,
          },
        },
      ]}
      onFilter={handleFilters}
      onEdit={onEdit}
      onSave={onSave}
    />
  );
}

export default Ejercicio;
