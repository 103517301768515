import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  makeStyles,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { endOfDay, format, formatDistance, isFuture, isPast, parseISO } from "date-fns";
import esLocale from "date-fns/locale/es";
import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { useCountUp } from "react-countup";
import Api, { handleError } from "../../../Restful/Api";
import LoadingButton from "../../Helpers/LoadingButton/LoadingButton";

/**
 * Estilos
 */
const useStyles = makeStyles((theme) => ({
  ejercicios: {
    width: "95%",
  },
  estado: {
    marginTop: theme.spacing(2),
  },
  accordionHeader: { flexBasis: "33.33%", flexShrink: 0 },
  chips: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));

/**
 * Panel que permite ver los ejercicios en proceso
 *
 * @category Administración
 * @version 1.0.5
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Dashboard() {
  /**
   * Estilos
   */
  const classes = useStyles();

  /**
   * Hook para manipular la bandera de carga
   */
  const [loading, setLoading] = useState(true);
  /**
   * Hook para manipular los ejercicios
   */
  const [ejercicios, setEjercicios] = useState([]);

  /**
   * Hook para manipular las participantes
   */
  const [participantes, setParticipantes] = useState([]);

  /**
   * Hook para manipular las rondas
   */
  const [rondas, setRondas] = useState([]);

  /**
   * Hook para contador de ejercicios
   */
  const {
    countUp: ejercicioCountUp,
    reset: resetEjerciciosCountUp,
    update: updateEjerciciosCountUp,
  } = useCountUp({ end: 0 });

  /**
   * Hook para contador de participantes
   */

  const {
    countUp: participantesCountUp,
    reset: resetParticipantesCountUp,
    update: updateParticipantesCountUp,
  } = useCountUp({ end: 0 });

  /**
   * Hook para mostrar snackbar
   */
  const [showSnackbar, setShowSnackbar] = useState(false);

  /**
   * Hook para manipular mensaje de snackbar
   */
  const [message, setMessage] = useState("");

  /**
   * Objeto con las banderas que sirven para saber si un ejercicio
   *  o ronda estan activos
   */
  const activos = { estatus: true };

  /**
   * Obtener los ejercios para filtrado y formulario haciendo uso del endpoint findAll
   * seteando el param size a 1000 para evitar paginado
   */

  useEffect(() => {
    if (loading) {
      Api.get("api/ejercicio", { params: { size: 1000, ...activos } })
        .then((response) => {
          setEjercicios(response.data.data);
        })
        .catch((error) => showMessage(handleError(error)))
        .finally(() => setLoading(false));
    }
    return () => {};
    // eslint-disable-next-line
  }, [loading]);

  /**
   * Obtener los participante y rondas asociadas a los ejercicios activos
   */
  useEffect(() => {
    if (ejercicios.length) {
      resetEjerciciosCountUp();
      updateEjerciciosCountUp(ejercicios.length);

      const ejercicioIds = ejercicios.map((ejercicio) => ejercicio.id);
      Promise.all([
        Api.get("api/usuario", {
          params: { size: 1000, ejercicioIds },
        }),
        Api.get("api/ronda", {
          params: { size: 1000, ejercicioIds, ...activos },
        }),
      ]).then((results) => {
        setParticipantes(results[0].data.data);
        setRondas(results[1].data.data);
      });
    }
    return () => {};
    // eslint-disable-next-line
  }, [ejercicios]);

  /**
   * recargar la animación de cuenta para los participantes
   */
  useEffect(() => {
    resetParticipantesCountUp();
    updateParticipantesCountUp(participantes.length);
    return () => {};
    // eslint-disable-next-line
  }, [participantes]);

  const showMessage = (message) => {
    if (message) {
      setMessage(message);
      setShowSnackbar(true);
    } else {
      setShowSnackbar(false);
      setMessage("");
    }
  };

  /**
   * descargar un reporte en formato csv
   *
   * @param {object} ronda la ronda
   * @param {number} tipoReporte el tipo de reporte
   *
   * @version 1.0.1
   * @author Ing. Roberto ALonso De la Garza Mendoza
   */
  const descargarReporte = (ronda, tipoReporte) => {
    showMessage("Descargando reporte...");
    switch (tipoReporte) {
      case 1:
        downloadFile(
          `api/reporte/totalVotos/ronda/${ronda.id}`,
          `indicadores-elegidos-${normalizarTexto(ronda.descripcion)}.csv`
        );
        break;
      case 2:
        downloadFile(
          `api/reporte/indicadoresPropuestos/ronda/${ronda.id}`,
          `indicadores-propuestos-${normalizarTexto(ronda.descripcion)}.csv`
        );
        break;
      case 3:
        downloadFile(
          `api/reporte/actividadUsuariosVotos/ronda/${ronda.id}`,
          `actividad-${normalizarTexto(ronda.descripcion)}.csv`
        );
        break;
      case 4:
        downloadFile(
          `api/reporte/totalMetas/ronda/${ronda.id}`,
          `total-metas-${normalizarTexto(ronda.descripcion)}.csv`
        );
        break;
      case 5:
        downloadFile(
          `api/reporte/actividadUsuariosMetas/ronda/${ronda.id}`,
          `actividad-${normalizarTexto(ronda.descripcion)}.csv`
        );
        break;
      case 6:
        downloadFile(
          `api/reporte/totalVotosDesglosado/ronda/${ronda.id}`,
          `lista-votos-${ronda.descripcion}.csv`
        );
        break;
      case 7:
        downloadFile(
          `api/reporte/totalMetasDesglosado/ronda/${ronda.id}`,
          `lista-metas-${ronda.descripcion}.csv`
        );
        break;
      default:
        break;
    }
  };

  /**
   * Quitar espacios en blanco
   *
   * @param {string} texto Texto a normalizar
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const normalizarTexto = (texto) => {
    return texto.replace(" ", "-");
  };

  /**
   * Descarga el archivo
   *
   * @param {string} url direccion donde descarga el archivo
   * @param {string} nombreArchivo nombre del archivo
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const downloadFile = (url, nombreArchivo) => {
    Api.get(url, { responseType: "blob", timeout: 30000 })
      .then((response) => {
        FileSaver.saveAs(response.data, nombreArchivo);
      })
      .catch(async (error) => {
        try {
          const plainText = await fileParser(error.response.data);
          const json = JSON.parse(plainText);
          showMessage(
            handleError({ response: { ...error.response, data: json } })
          );
        } catch (error) {
          console.log(error);
        }
      });
  };

  /**
   * Leer un objecto binario a texto plano
   *
   * @param {object} file Blob
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const fileParser = (file) => {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort(reject(new Error("Un problema al obtener el error")));
      };
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.readAsText(file);
    });
  };

  /**
   * Muestra los chips para descargar el reporte dependiendo del tipo
   * de ronda
   *
   * @param {object} ronda la ronda
   * @param {number} ronda.tipo_ronda el tipo de ronda
   *
   * @version 1.0.1
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const showChips = (ronda) => {
    switch (ronda.tipo_ronda) {
      case 0:
        return (
          <>
            {/* Reporte 1 */}
            <Chip
              label="Indicadores"
              onClick={() => descargarReporte(ronda, 1)}
              color="secondary"
              variant="default"
            />
            {/* Reporte 2 */}
            <Chip
              label="Propuestas"
              onClick={() => descargarReporte(ronda, 2)}
              color="secondary"
              variant="default"
            />
            {/* Reporte 3 */}
            <Chip
              label="Monitoreo"
              onClick={() => descargarReporte(ronda, 3)}
              color="secondary"
              variant="default"
            />
            {/* Reporte 6 */}
            <Chip
              label="Votos"
              onClick={() => descargarReporte(ronda, 6)}
              color="secondary"
              variant="default"
            />
          </>
        );
      case 1:
        return (
          <>
            {/* Reporte 1 */}
            <Chip
              label="Indicadores"
              onClick={() => descargarReporte(ronda, 1)}
              color="secondary"
              variant="default"
            />
            {/* Reporte 3 */}
            <Chip
              label="Monitoreo"
              onClick={() => descargarReporte(ronda, 3)}
              color="secondary"
              variant="default"
            />
            {/* Reporte 6 */}
            <Chip
              label="Votos"
              onClick={() => descargarReporte(ronda, 6)}
              color="secondary"
              variant="default"
            />
          </>
        );
      case 2:
        return (
          <>
            {/* Reporte 4 */}
            <Chip
              label="Metas"
              onClick={() => descargarReporte(ronda, 4)}
              color="secondary"
              variant="default"
            />
            {/* Reporte 5 */}
            <Chip
              label="Monitoreo"
              onClick={() => descargarReporte(ronda, 5)}
              color="secondary"
              variant="default"
            />
            {/* Reporte 7 */}
            <Chip
              label="Detalles"
              onClick={() => descargarReporte(ronda, 7)}
              color="secondary"
              variant="default"
            />
          </>
        );
      default:
        break;
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          container
          justify="flex-start"
          xs={12}
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={12} md>
            <Card>
              <CardContent>
                <Typography variant="h4" component="h2" color="primary">
                  {ejercicioCountUp}
                </Typography>
                <Typography color="textSecondary">
                  Ejercicios activos
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md>
            <Card>
              <CardContent>
                <Typography variant="h4" component="h2" color="primary">
                  {participantesCountUp}
                </Typography>
                <Typography color="textSecondary">Participantes</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={5} container justify="center">
            <LoadingButton
              loading={loading}
              text="Recargar"
              loading_text="Actualizando..."
              color="primary"
              onClick={() => setLoading(true)}
            ></LoadingButton>
          </Grid>
        </Grid>
        {!loading &&
          ejercicios.map((ejercicio) => (
            <Grid key={ejercicio.id} item xs={12}>
              <Accordion className={classes.ejercicios} variant="elevation">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className={classes.accordionHeader}>
                    {ejercicio.nombre}
                  </Typography>
                  <Typography color="textSecondary">{`${format(
                    new Date(ejercicio.fecha_inicio),
                    "dd-MM-yyyy"
                  )} - ${format(
                    new Date(ejercicio.fecha_fin),
                    "dd-MM-yyyy"
                  )} | quedan ${formatDistance(
                    Date.now(),
                    endOfDay(parseISO(ejercicio.fecha_fin)),
                    {
                      locale: esLocale,
                    }
                  )}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} justify="space-evenly">
                    {rondas
                      .filter((ronda) => ronda.ejercicioId === ejercicio.id)
                      .map((ronda) => (
                        <Grid key={ronda.id} item>
                          <Card variant="outlined">
                            <CardContent>
                              <Typography color="textSecondary" gutterBottom>
                                {`${format(
                                  new Date(ronda.fecha_inicio),
                                  "dd-MM-yyyy"
                                )} - ${format(
                                  new Date(ronda.fecha_fin),
                                  "dd-MM-yyyy"
                                )}`}
                              </Typography>
                              <Typography
                                variant="h6"
                                component="h2"
                                color="primary"
                              >
                                {ronda.descripcion}
                              </Typography>
                              <Typography
                                className={classes.estado}
                                color="textSecondary"
                              >
                                {isFuture(new Date(ronda.fecha_inicio))
                                  ? "Disponible"
                                  : isPast(endOfDay(parseISO(ronda.fecha_fin)))
                                  ? "Finalizada"
                                  : `En proceso - quedan ${formatDistance(
                                      Date.now(),
                                      endOfDay(parseISO(ronda.fecha_fin)),
                                      { locale: esLocale }
                                    )}`}
                              </Typography>
                              <Divider className={classes.divider} />
                              <div className={classes.chips}>
                                {showChips(ronda)}
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
      </Grid>
      <Snackbar
        open={showSnackbar}
        message={message}
        autoHideDuration={1000}
        onClose={() => showMessage()}
      />
    </>
  );
}

export default Dashboard;
