/**
 * @module Redux/Reducers
 */
/**
 * Reducer usado por redux para manipular la bandera de login
 * @param {boolean} [state=false] - Estado inicial
 * @param {object} action - parametros para realizar
 * @param {string} action.type la accion a realizar
 * @param {*=} action.payload datos para realizar la acción
 * 
 * @returns {boolean} - Estado modificado
 * 
 * @version 1.0.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
const loginReducer = (state = false, action) => {
  switch (action.type) {
    case "LOG_IN":
      return (state = true);
    case "LOG_OUT":
      return (state = false);
    default:
      return state;
  }
};

export default loginReducer;
