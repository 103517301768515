import React, { useEffect, useState } from 'react';
import './Indicador.css';
import {
  Paper,
  MobileStepper,
  RadioGroup,
  FormControlLabel,
  Radio,
  List,
  ListItemText,
  ListItem,
  Fab,
  Divider,
  ListSubheader,
  ListItemSecondaryAction,
  IconButton,
  Hidden,
  Grid,
  Snackbar,
  Tooltip,
  Button,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';

import Carusel from './Carusel/Carusel';
import { useHistory, useParams } from 'react-router-dom';

import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import PropuestaForm from './PropuestaForm/PropuestaForm';
import Api, { handleError } from '../../Restful/Api';
import { connect } from 'react-redux';
import LoadingButton from '../Helpers/LoadingButton/LoadingButton';
import Skeleton from '../Helpers/Skeleton';

import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
/**
 * Componente que muestra un listado de elementos para se evaluado de manera individual, con la posibilidad
 * de recorrerlos de manera libre y enviar las respuestas una vez terminado el ejercicio
 *
 * @param {props} props - El tipo de ronda a setear
 *
 * @category Votación
 * @version 2.1.6
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Indicador({ tipoRonda, ronda }) {
  /**
   * Hook para obtener parametros del url
   */
  let { tipo, idIndicador } = useParams();
  /**
   * Hook para poder manipular la navegador del sitio
   */
  let history = useHistory();

  /**
   * Hook para manipular el indicador actual
   */
  const [indicador, setIndicador] = useState('');
  /**
   * Hook para manipular el visionado de cuadro de razon
   */
  const [mostrar_cuadro_razon, setMostraRazon] = useState(false);
  /**
   * Hook para manipular la posición dentro del arreglo de elementos (indicadores)
   */
  const [step, setStep] = useState(0);
  /**
   * Hook para manipular el estado de finalización del ejercicio
   */
  const [finalizar, setFinalizar] = useState(false);
  /**
   * Hook para manipular el visionado del panel derecho de componente
   */
  const [estado_panel_finalizar, setEstadoPanelFinalizar] = useState(false);
  /**
   * Hook para manipular la lista de elementos a mostrar (indicadores)
   */
  const [indicadores, setIndicadores] = useState([]);
  /**
   * Hook para manipular una lista de elementos propuestos por el usuario (propuestas)
   */
  const [propuestas, setPropuestas] = useState([]);
  /**
   * Hook para manipular el visionado del modal para agregar elementos nuevos (propuesta)
   */
  const [dialog_propuesta, setDialogPropuesta] = useState(false);

  /**
   * Hook para manipular el estado de carga
   */
  const [loading, setLoading] = useState(true);
  /**
   * Hook para manipular el estado de envio de información
   */
  const [sending, setSending] = useState(false);

  /**
   * Hook para manipular el visionado del Snackbar
   */
  const [message_snackbar, setMessageSnackbar] = useState(false);
  /**
   * Hook para manipular el mansaje a mostar en el Snackbar
   */
  const [message, setMessage] = useState('');

  /**
   * Hook para manipular el visionado del Tour
   */
  const [runJoyride, setRunJoyride] = useState(false);
  /**
   * Hook para manipular la posición en el Tour
   */
  const [joyride_index_step, setJoyride_index_step] = useState(0);

  /**
   * Texto de ayuda para el botón que agregá elementos (propuestas)
   */
  let tooltip_propuesta =
    'Agregar un nuevo indicador diferente a los mostrados';

  /**
   * mostrar un mensaje en el fondo de la pantalla
   * @param {string} message - El mensaje a mostrar
   * @version 1.0.0
   */
  const showSnackbar = (message) => {
    setMessage(message);
    setMessageSnackbar(true);
  };
  /**
   * Consumo de API
   */
  useEffect(() => {
    //console.log("ronda>>>", ronda);
    let tipourl;
    switch (tipo) {
      case 'aspiracion':
        tipourl = 'aspiracion';
        break;
      case 'objetivo':
        tipourl = 'objetivo';
        break;
      case 'linea':
        tipourl = 'lineaestrategica';
        break;
      default:
        tipourl = 'error';
        break;
    }
    setLoading(true);
    Api.get(
      `/${tipourl}/${tipoRonda === 'meta' ? 'metas' : 'votos'}/${idIndicador}/${
        ronda.id
      }`
    )
      .then((response) => {
        //console.log(response);
        switch (response.status) {
          case 200:
            let data = response.data;
            setIndicador(data.descripcion);
            setIndicadores(buildIndicadores(data.indicadors, tipoRonda));
            setLoading(false);
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log('error >>>', error);
      })
      .finally(() => {
        localStorage.getItem('ayuda_indicador_vista') !== 'si' &&
          setRunJoyride(true);
      });
    if (tipoRonda === 'indicador') {
      Api.get(`${tipourl}/indicadoresPropuestos/${idIndicador}/${ronda.id}`)
        .then((response) => {
          switch (response.status) {
            case 200:
              setPropuestas(response.data.indicador_propuestos);
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log('error >>>', error);
        });
    }
    // eslint-disable-next-line
  }, []);
  /**
   * Formatea la respuesta de la api a un arreglo manipulable sin importar el tipo de ronda
   * @param {Array<object>} indicadores lista de elementos a formatear
   * @param {string} tipo_ronda tipo de la ronda para condicionar el formateo
   * @returns {Array<Object>} - lista formateada
   * @version 1.1.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const buildIndicadores = (indicadores, tipo_ronda) => {
    switch (tipo_ronda) {
      case 'indicador':
        return indicadores.map((indicador) => {
          let voto = indicador.votos.length
            ? indicador.votos[0]
            : {
                id: null,
                tipo_voto: '99',
                razon_no: '',
              };
          return {
            ...indicador,
            voto: {
              ...voto,
              tipo_voto: `${voto.tipo_voto}`,
            },
          };
        });
      case 'meta':
        return indicadores.map((indicador) => {
          let voto = indicador.meta.length
            ? indicador.meta[0]
            : {
                id: null,
                certeza: '99',
                razon_no: '',
                valor_meta: '',
              };
          return {
            ...indicador,
            voto: {
              ...voto,
              tipo_voto: `${voto.certeza}`,
              valor_meta: voto.certeza === 99 ? '' : voto.valor_meta,
            },
          };
        });
      default:
        break;
    }
  };
  /**
   * - Muestra el panel derecho segun la posición de los elementos (indicadores)
   * - Muestra caja de texto según el tipo de respuesta
   */
  useEffect(() => {
    if (step === indicadores.length - 1) {
      setFinalizar(true);
    } else {
      setFinalizar(false);
      setEstadoPanelFinalizar(false);
    }
    if (
      tipoRonda === 'indicador' &&
      indicadores[step]?.voto.tipo_voto === '1'
    ) {
      setMostraRazon(true);
    } else {
      setMostraRazon(false);
    }
    // eslint-disable-next-line
  }, [step, indicadores]);
  /**
   * Opciones para el grupo de radiobutton que cambia segun el tipo de ronda
   * @returns {Array<Object>} - lista de opciones
   * @version 1.0.1
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  let opciones =
    tipoRonda === 'meta'
      ? [
          {
            value: '0',
            label: 'POCO',
          },
          {
            value: '1',
            label: 'REGULAR',
          },
          {
            value: '2',
            label: 'ALTA',
          },
        ]
      : [
          {
            value: '0',
            label: 'SÍ',
          },
          {
            value: '1',
            label: 'NO',
          },
          {
            value: '2',
            label: 'Prefiere no responder',
          },
        ];
  /**
   * Avanza una posición en la lista de elementos
   */
  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };
  /**
   * Retrocede una posición en la lista de elementos
   */
  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  /**
   * Recibe un eventos del formulario y asigna valores segun el origen
   * @param {*} e - el evento del formulario
   * @version 2.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleInput = (e) => {
    let indicador = indicadores[step];
    switch (e.target.type) {
      case 'radio':
        //setRespuesta(e.target.value);
        if (tipoRonda === 'indicador' && e.target.value === '1') {
          setMostraRazon(true);
        } else {
          setMostraRazon(false);
          indicador.voto.razon_no = '';
        }
        indicador.voto.tipo_voto = e.target.value;
        break;
      case 'textarea':
        //setRazon(e.target.value);
        indicador.voto.razon_no = e.target.value;
        break;
      case 'number':
        indicador.voto.valor_meta = e.target.value;
        break;
      default:
        break;
    }
    let new_indicadores = indicadores.map((old_indicador) => {
      if (old_indicador.id === indicador.id) {
        return indicador;
      } else {
        return old_indicador;
      }
    });
    setIndicadores(new_indicadores);
  };
  /**
   * Verfica el tipo de ronda y envía la información correspondientes a los diferentes
   * enpoint segun sea el caso, una vez se valida la recepción de los datos se regresa
   * a la ventana de selección
   * @throws - Imprime en el snackbar los errores correspondientes
   * @version 1.2.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleSalir = () => {
    if (indicadores.length === 0) {
      history.push('/ronda');
    } else {
      setSending(true);
      showSnackbar('Guardando cambios...');
      let sends = [];
      switch (ronda.tipo_ronda) {
        case 0:
          sends.push(enviarVotos());
          sends.push(enviarPropuestas());
          break;
        case 1:
          sends.push(enviarVotos());
          break;
        case 2:
          sends.push(enviarMetas());
          break;
        default:
          break;
      }
      Promise.all(sends)
        .then((response) => {
          setSending(false);
          //console.log(response);
          showSnackbar('Cambios guardados, regresando al listado...');
          setTimeout(() => {
            history.push(`/ronda`);
          }, 600);
        })
        .catch((error) => {
          setSending(false);
          showSnackbar(handleError(error));
        });
    }
  };
  /**
   * Enviar un arreglo listo para ser consumido por el endpoint que almacena votos en el API
   * @returns {Promise<*>} Promesa que representa el envió de votos
   * @version 1.0.1
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const enviarVotos = () => {
    return Api.put(
      `/voto/${ronda.id}`,
      indicadores.map((indicador) => {
        return {
          ...indicador,
          voto: {
            ...indicador.voto,
            tipo_voto: indicador.voto.tipo_voto * 1,
          },
        };
      })
    );
  };
  /**
   * Enviar un arreglo listo para ser consumido por el endpoint que almacena metas en el API
   * @returns {Promise<*>} Promesa que representa el envió de votos
   * @version 1.1.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const enviarMetas = () => {
    return Api.post(
      `/meta/${ronda.id}`,
      indicadores.map((indicador) => {
        return {
          ...indicador,
          meta: {
            ...indicador.voto,
            certeza: indicador.voto.tipo_voto * 1,
            comentario: indicador.voto.razon_no,
            valor_meta:
              indicador.voto.tipo_voto === '99'
                ? 99
                : indicador.voto.valor_meta,
          },
        };
      })
    );
  };
  /**
   * Enviar un arreglo listo para ser consumido por el endpoint que almacena propuestas en el API
   * @returns {Promise<*>} Promesa que representa el envió de votos
   * @version 1.0.1
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const enviarPropuestas = () => {
    return Api.post(`/indicadorPropuesto/${ronda.id}`, {
      indicadoresPropuestos: propuestas,
    });
  };
  /**
   * Elimina un elemento de la lista creada por el usuario
   * @param {number} index - Posición del elementos a eliminar de la lista
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleDeletePropuesta = (index) => {
    let p = propuestas;
    p.splice(index, 1);
    setPropuestas(
      p.map((propuesta) => {
        return propuesta;
      })
    );
  };
  /**
   * Función que permite la manipulación atomica de cada acción del tour
   * @param {*} data - Callback de eventos del tour
   * @version 1.1.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleJoyrideCallBack = (data) => {
    const { action, index, status, type } = data;
    if (index === 3 || index === 4) {
      finalizar === false && setFinalizar(true);
    } else {
      indicadores.length >= 2 && setFinalizar(false);
    }
    if (index === 4) {
      setEstadoPanelFinalizar(true);
    } else {
      setEstadoPanelFinalizar(false);
    }
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      if (index === 2 || index === 3 || index === 4) {
        setRunJoyride(false);

        setTimeout(() => {
          setRunJoyride(true);
          setJoyride_index_step(index + (action === ACTIONS.PREV ? -1 : 1));
        }, 500);
      } else {
        setJoyride_index_step(index + (action === ACTIONS.PREV ? -1 : 1));
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setJoyride_index_step(0);
      setRunJoyride(false);
      localStorage.setItem('ayuda_indicador_vista', 'si');
    }
    if (action === ACTIONS.CLOSE) {
      setJoyride_index_step(0);
      setRunJoyride(false);
      localStorage.setItem('ayuda_indicador_vista', 'si');
    }
  };

  return (
    <div className='RondaPanel'>
      <div className='Login_logo_section'>
        <img
          alt='logo_nuevo_leon'
          className='Login_logo'
          src='https://delphiconl.s3.us-east-2.amazonaws.com/front/nuevo_leon_logo.svg'
        />
      </div>
      <Paper variant='elevation' elevation={2} className='panel_card'>
        <div
          className={`indicador y-scroll ${
            !estado_panel_finalizar ? 'active' : ''
          }`}
        >
          <div className='sticky-top on-top'>
            <Grid direction='row' container justify='space-between' spacing={2}>
              <Grid item xs={8}>
                <LoadingButton
                  className='exit-button'
                  loading={sending}
                  text='Regresar al listado'
                  loading_text='Enviando'
                  onClick={handleSalir}
                  variant='contained'
                  color='primary'
                />
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => setRunJoyride(true)}
                >
                  Ayuda
                </Button>
              </Grid>
            </Grid>
          </div>
          <Typography variant='h5' align='center'>
            {loading ? <Skeleton height='5rem' /> : indicador}
          </Typography>
          <Typography variant='h6' align='center' color='primary'>
            {loading ? (
              <Skeleton height='2rem' />
            ) : (
              <>
                <strong>Indicador: </strong>
                {indicadores[step]?.nombre}
              </>
            )}
          </Typography>
          <Grid container>
            {indicadores[step]?.images?.length > 0 && (
              <Grid item xs={12} sm>
                <Carusel
                  loading={loading}
                  imagenes={indicadores[step]?.images}
                />
              </Grid>
            )}
            <Grid className='definicion_fuente' item xs={12} sm>
              {loading ? (
                <>
                  <Skeleton marginTop='1rem' />
                  <Skeleton marginTop='1rem' />
                </>
              ) : (
                <>
                  <Typography variant='body1' className='bold' paragraph>
                    <strong>Definición:</strong> {indicadores[step]?.definicion}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Fuente:</strong> {indicadores[step]?.fuente}
                  </Typography>
                  {ronda.tipo_ronda === 1 && (
                    <Typography variant='body1'>
                      <strong>Argumentos:</strong>{' '}
                      {indicadores[step]?.texto_explicativo}
                    </Typography>
                  )}
                  <Paper variant='outlined' className='form'>
                    {tipoRonda === 'meta' && (
                      <div>
                        {loading ? (
                          <Skeleton marginTop='1rem' />
                        ) : (
                          <Typography variant='body1'>
                            ¿Cuál es la meta que debe establecerse?​
                          </Typography>
                        )}
                        {loading ? (
                          <Skeleton />
                        ) : (
                          <TextField
                            fullWidth
                            autoFocus
                            type='number'
                            value={indicadores[step]?.voto.valor_meta}
                            onChange={handleInput}
                            label={indicadores[step]?.unidad_medida}
                            variant='filled'
                          />
                        )}
                      </div>
                    )}
                    <div>
                      {loading ? (
                        <Skeleton marginTop='1rem' />
                      ) : tipoRonda === 'meta' ? (
                        <Typography variant='body1'>
                          ¿Qué tan probable es alcanzar la meta planteada?​
                        </Typography>
                      ) : (
                        <Typography variant='body1'>
                          {
                            '¿Considera usted que este indicador contribuye a monitorear el avance?'
                          }
                        </Typography>
                      )}
                      {loading ? (
                        <Skeleton marginTop='1rem' />
                      ) : (
                        <Accordion square expanded={mostrar_cuadro_razon}>
                          <AccordionSummary>
                            <RadioGroup
                              className='opciones_ronda'
                              aria-label='gender'
                              name='inclusion'
                              value={indicadores[step]?.voto.tipo_voto}
                              onChange={handleInput}
                            >
                              {opciones.map((opcion, index) => (
                                <FormControlLabel
                                  key={index}
                                  value={opcion.value}
                                  control={<Radio />}
                                  label={opcion.label}
                                />
                              ))}
                            </RadioGroup>
                          </AccordionSummary>
                          <AccordionDetails>
                            {tipoRonda === 'indicador' && (
                              <TextField
                                fullWidth
                                multiline
                                variant='filled'
                                rows={15}
                                placeholder='Escriba las razones por las cuales no debería incluirse este indicador: ​'
                                value={indicadores[step]?.voto.razon_no}
                                onChange={handleInput}
                              />
                            )}
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </div>
                  </Paper>
                </>
              )}
            </Grid>
          </Grid>
          <div className='sticky-bottom'>
            {loading ? (
              <Skeleton />
            ) : indicadores.length >= 2 ? (
              <MobileStepper
                className='indicador-stepper'
                variant='progress'
                steps={indicadores.length}
                position='static'
                activeStep={step}
                nextButton={
                  <Button
                    variant='text'
                    color='secondary'
                    onClick={handleNext}
                    className='button-stepper'
                    disabled={
                      (tipoRonda === 'indicador' &&
                        indicadores[step]?.voto.tipo_voto === '1' &&
                        indicadores[step]?.voto.razon_no === '') ||
                      step === indicadores.length - 1
                    }
                  >
                    siguiente
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button
                    variant='text'
                    color='secondary'
                    onClick={handleBack}
                    className='button-stepper'
                    disabled={step === 0}
                  >
                    <KeyboardArrowLeft />
                    anterior
                  </Button>
                }
              ></MobileStepper>
            ) : null}
          </div>
        </div>
        <Hidden smDown>
          <div
            className={`total-indicadores ${
              estado_panel_finalizar && 'active'
            }`}
          >
            <Fab
              variant='extended'
              className={`finish-button fab azul ${finalizar && 'hidden'}`}
              onClick={() => setEstadoPanelFinalizar(!estado_panel_finalizar)}
            >
              {!estado_panel_finalizar ? <ArrowForwardIos /> : <ArrowBackIos />}
              {!estado_panel_finalizar ? 'finalizar' : 'regresar'}
            </Fab>
            <div className='informacion y-scroll'>
              {loading ? (
                Array(6)
                  .fill()
                  .map((elem, index) => (
                    <div key={index}>
                      <Skeleton marginTop='1.5rem' />
                      <Skeleton marginTop='1rem' height='.5rem' />
                    </div>
                  ))
              ) : (
                <List className='lista-indicadores'>
                  {indicadores.map((indicador) => {
                    let voto = indicador.voto.tipo_voto;
                    //console.log("valor voto>>>", voto);
                    let opcion =
                      voto === '99'
                        ? { label: 'Sin seleccionar' }
                        : opciones.find((opcion) => opcion.value === voto);
                    //console.log("valor opcion>>>", opcion);
                    return (
                      <div key={indicador.id}>
                        <ListItem>
                          <ListItemText
                            primary={indicador.nombre}
                            secondary={opcion?.label}
                          />
                        </ListItem>
                        <Divider />
                      </div>
                    );
                  })}
                  {ronda.tipo_ronda === 0 && (
                    <>
                      <Divider />
                      <ListSubheader>Propuestas</ListSubheader>
                    </>
                  )}

                  {ronda.tipo_ronda === 0 &&
                    propuestas.map((propuesta, index) => (
                      <div key={index}>
                        <ListItem>
                          <ListItemText
                            primary={propuesta.nombre}
                            secondary={propuesta.razon_no}
                          />
                          {propuesta.id === null && (
                            <ListItemSecondaryAction>
                              <IconButton
                                edge='end'
                                aria-label='eliminar'
                                onClick={() => {
                                  handleDeletePropuesta(index);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          )}
                        </ListItem>
                        <Divider />
                      </div>
                    ))}
                </List>
              )}
              <div
                className={`sending-button bottom_button sticky-bottom ${
                  estado_panel_finalizar ? 'active' : ''
                }`}
              >
                {ronda.tipo_ronda === 0 && (
                  <Tooltip arrow placement='bottom' title={tooltip_propuesta}>
                    <Button
                      disabled={propuestas.length === 20}
                      variant='contained'
                      color='primary'
                      onClick={() => setDialogPropuesta(true)}
                    >
                      Proponer indicadores
                    </Button>
                  </Tooltip>
                )}
                <LoadingButton
                  variant='contained'
                  color='secondary'
                  loading={sending}
                  text='Finalizar y enviar respuestas'
                  loading_text='Enviando'
                  onClick={handleSalir}
                />
              </div>
            </div>
          </div>
        </Hidden>
      </Paper>
      {/* Propuesta Dialog */}
      <PropuestaForm
        idIndicador={idIndicador}
        tipo={tipo}
        flag_open={dialog_propuesta}
        handleClose={() => setDialogPropuesta(false)}
        setPropuestas={(propuesta) => {
          setPropuestas((propuestas) => [...propuestas, propuesta]);
        }}
      />
      {/* Snackbar section */}
      <Snackbar
        message={message}
        open={message_snackbar}
        autoHideDuration={5000}
        onClose={() => {
          setMessageSnackbar(false);
        }}
      />
      {/* Joyride Tour */}
      <Joyride
        run={runJoyride}
        continuous
        callback={handleJoyrideCallBack}
        stepIndex={joyride_index_step}
        styles={{
          options: {
            primaryColor: '#5a2149',
          },
        }}
        steps={[
          {
            target: '.form',
            content: 'Seleciona la opción que más se ajuste a tu opinión',
            disableBeacon: true,
          },
          {
            target: '.indicador-stepper',
            content: 'Utiliza los botones para moverte entre los indicadores',
          },
          {
            target: '.lista-indicadores',
            content: 'Revisa y ten un estado de tus votos en todo momento',
            placement: 'left',
          },
          {
            target: '.finish-button',
            content:
              'Al finalizar todos tus votos se mostrará este boton, al dar clic se abrirá la siguiente ventana',
          },
          {
            target: '.sending-button',
            content:
              'Cuando te encuentres seguro de finalizar tu votación, da clic en este botón para enviar tus respuestas',
          },
          {
            target: '.exit-button',
            content:
              'Utiliza este botón para salir de la votación, esta se guardará para regresar y finalizar más tarde',
          },
        ]}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ronda: state.ronda,
    tipoRonda: state.ronda.tipo_ronda === 2 ? 'meta' : 'indicador',
  };
};

export default connect(mapStateToProps)(Indicador);
