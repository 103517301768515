import {
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
} from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import Formulario from "./Formulario/Formulario";
import Listado from "./Listado/Listado";

import Api, { handleError } from "../../../Restful/Api";

/**
 * Elemento para implentar una intefaz CRUD para un elemento
 * @param {object} props
 * @param {object[]} props.inputs - lista de inputs a manipular
 * @param {object[]} props.columnas - Lista de columnas para la tabla asi como los filtros que se mostraran
 * @param {string} props.findAllUrl - Endpoint para obtener listado de registros
 * @param {string} props.findByIdUrl - Endpoint para obtener un registro
 * @param {string} props.createUrl - Endpoint para poder crear un registro
 * @param {string} props.updateUrl - Endpoint para poder editar un registro
 * @param {string} props.deleteUrl - Endpoint para poder eliminar un registro
 * @param {function=} props.onSave - Función que permite procesar los datos a ser guardados
 * @param {function=} props.onEdit - Función a llamar cuando se editar un registro, esta debe regresar un objeto
 * con los datos obtenidos del registro selecionado procesados
 * @param {function=} props.onFilter Función que permite procesar los datos para el filtrado
 * @param {string=} props.nombre -Nombre del elemento
 * @param {object=} props.FormularioProps - Props a pasar hacia el formulario
 * @param {object=} props.permanentFilters - Objecto con filtros a agregar de manera albitraria
 *
 * @version 1.1.1
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Crud({
  nombre,
  inputs,
  columnas,
  findAllUrl,
  findByIdUrl,
  createUrl,
  updateUrl,
  deleteUrl,
  onSave,
  onEdit,
  onFilter,
  FormularioProps,
  permanentFilters,
  ...props
}) {
  /**
   * Hook para manejo el dialogo del formulario
   */
  const [open_dialog, setOpen_dialog] = useState(false);
  /**
   * Hook para guardar los datos del registro a editar
   */
  const [registro, setRegistro] = useState({});
  /**
   * Hook para manipular el titulo del dialogo del formulario
   */
  const [tituloDialog, setTituloDialog] = useState("");
  //hook para mensaje
  const [mensage, setMensage] = useState("");
  /**
   * hook para mostrar snackbar
   */
  const [showSnackbar, setShowSnackbar] = useState(false);
  /**
   * hook para los registros obtenidos del servidor
   */
  const [datos, setDatos] = useState([]);
  /**
   * Hook para manipular el estado de carga
   */
  const [loading, setLoading] = useState(false);
  /**
   * Hook para el número de página actual
   */
  const [page, setPage] = useState(0);
  /**
   * hook para número total de elementos
   */
  const [totalItems, setTotalItems] = useState(1);
  /**
   * Hook para la cantidad de registros por página
   */
  const [size, setSize] = useState(10);
  /**
   * Hook para los filtros del registro
   */
  const [filtros, setFiltros] = useState({});

  /*****************************************************************
   * Funciones CRUD
   *****************************************************************/
  /**
   * Metodo que se encarga de cargar los datos del servidor
   * @version 1.1.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const cargarDatos = () => {
    setLoading(true);
    Api.get(findAllUrl, {
      params: {
        page,
        size,
        ...filtros,
        ...permanentFilters,
      },
    })
      .then((response) => {
        //console.log(response);
        //asignar lista de registros
        setDatos(response.data.data);
        //asignar la pagina actual
        setPage(response.data.currentPage);
        //asignar el total de elementos
        setTotalItems(response.data.totalItems);
        setLoading(false);
      })
      .catch((error) => {
        //console.log(handleError(error));
        mostrarMensaje(handleError(error));
        setLoading(false);
      });
  };
  /**
   * Funcion que se encarga de enviar los datos a guardar
   * @param {object} data datos del formulario
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleSave = (data) => {
    const { id } = data;
    if (onSave && typeof onSave === "function") {
      data = onSave(data);
    }
    if (id === "") {
      Api.post(createUrl, data)
        .then((response) => {
          handleClose();
          mostrarMensaje(response.data.message);
          cargarDatos();
        })
        .catch((error) => {
          mostrarMensaje(handleError(error));
        });
    } else {
      Api.put(`${updateUrl}${id}`, data)
        .then((response) => {
          handleClose();
          mostrarMensaje(response.data.message);
          cargarDatos();
        })
        .catch((error) => {
          mostrarMensaje(handleError(error));
        });
    }
  };
  /**
   * Función que obtiene la información del registro
   * @param {number} id El id del registro
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleEdit = (id) => {
    Api.get(`${findByIdUrl}${id}`)
      .then((reponse) => {
        //console.log(reponse);
        var { data } = reponse;
        if (onEdit && typeof onEdit === "function") {
          data = onEdit(data);
        }
        setTituloDialog("Editar registro");
        handleOpenDialog(data);
      })
      .catch((error) => {
        //console.log(error);
        mostrarMensaje(handleError(error));
      });
  };
  /**
   * Activa o desactiva un registro
   * @param {object} id el registro a eliminar
   * @version 1.1.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleDelete = (registro) => {
    mostrarMensaje("Eliminando...");
    Api.delete(`${deleteUrl}${registro.id}`, {
      estatus: !registro.estatus,
    })
      .then((response) => {
        //console.log(response);
        mostrarMensaje(response.data.message);
        setDatos(datos.filter((dato) => dato.id !== registro.id));
      })
      .catch((error) => {
        //console.log(error);
        mostrarMensaje(handleError(error));
      });
  };
  /**
   * Función obtiene los un objecto con los filtros a aplicar en la carga de registros
   * @param {object} filtros objecto con los elementos a filtrar
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleFilters = (filtros) => {
    if (onFilter && typeof onFilter === "function") {
      filtros = onFilter(filtros);
    }
    mostrarMensaje("Filtrando...");
    const filtros_con_null = Object.fromEntries(
      Object.entries(filtros).map(([key, value]) => {
        if (value === "") {
          return [key, null];
        } else {
          return [key, value];
        }
      })
    );
    setFiltros(filtros_con_null);
  };

  /*****************************************************************
   * Funciones UI
   *****************************************************************/
  /**
   * hook para el listado,filtrado,cambio de pagina y de elementos por pagina
   */
  useEffect(() => {
    cargarDatos();
    return () => {
      setLoading(false);
    };
    // eslint-disable-next-line
  }, [page, size, filtros, permanentFilters]);

  /**
   * Carga los datos del registro a editar o abre
   * @param {object} registro El registro a mostrar en el dialogo con formulario
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleOpenDialog = (registro) => {
    setRegistro(registro);
    setOpen_dialog(true);
  };
  /**
   * Función que muestra una mensaje de unos segundos en la parte inferior de
   * la pantalla
   * @param {string} mensaje mensaje a mostrar en el Snackbar
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const mostrarMensaje = (mensaje) => {
    if (mensaje) {
      setMensage(mensaje);
      setShowSnackbar(true);
    }
  };

  /**
   * Cambiar el numero de pagina en el servidor
   * @param {number} page pagina actual
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const changePage = (page) => {
    setPage(page);
  };

  /**
   * Setea el número de página actual
   * @param {object} event El evento contiene el número de página actual
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
  };
  /**
   * Funcion que se encarga de cerrar el dialogo del formulario
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleClose = () => {
    setOpen_dialog(false);
  };

  return (
    <div className="Crud">
      <Listado
        columnas={columnas}
        addNew={() => {
          setTituloDialog("Crear registro");
          handleOpenDialog({});
        }}
        changeFiltrado={handleFilters}
        datos={datos}
        onDelete={handleDelete}
        onEdit={handleEdit}
        changePage={changePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        nombre={nombre}
        loading={loading}
        page={page}
        size={size}
        totalItems={totalItems}
        onReload={() => cargarDatos()}
        {...props}
      />
      {/* Dialogo con formulario */}
      <Dialog fullWidth maxWidth="lg" open={open_dialog} onClose={handleClose}>
        <DialogTitle>{tituloDialog}</DialogTitle>
        <DialogContent>
          <Formulario
            saveChanges={handleSave}
            inputs={inputs}
            onCancel={handleClose}
            data={registro}
            {...FormularioProps}
          />
        </DialogContent>
      </Dialog>
      {/* Snackbar para mostrar mensajes */}
      <Snackbar
        open={showSnackbar}
        message={mensage}
        autoHideDuration={2000}
        onClose={() => setShowSnackbar(false)}
      />
    </div>
  );
}

export default Crud;
