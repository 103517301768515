import React, { useEffect, useState } from "react";
import Api, { handleError } from "../../../Restful/Api";
import Crud from "../CRUD/Crud";

/**
 * Componente que muestra un formulario para manipular administradores
 *
 * @version 1.0.2
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Administrador() {
  /**
   * Hook para manipular los inputs del formulario
   */
  const [inputs, setInputs] = useState([
    {
      nombre: "id",
      tipo: "hidden",
    },
    {
      nombre: "nombre",
      etiqueta: "Nombre",
      tipo: "text",
      validacion: { required: "El campo es requerido" },
      config: {
        required: true,
        fullWidth: true,
      },
      grid: {
        xs: 12,
      },
    },
    {
      nombre: "usuario",
      etiqueta: "Usuario",
      tipo: "text",
      validacion: { required: "El campo es requerido" },
      config: {
        required: true,
        fullWidth: true,
      },
      grid: {
        xs: 6,
      },
    },
    {
      nombre: "contrasena",
      etiqueta: "Contraseña",
      tipo: "text",
      validacion: {
        //required: "El campo es requerido",
        maxLength: {
          value: 10,
          message: "La contraseña debe ser igual o menor a 10 caracteres",
        },
      },
      config: {
        required: true,
        fullWidth: true,
      },
      grid: {
        xs: 6,
      },
    },
  ]);

  /**
   * Hook para obtener los permisos existentes del servidor
   * y convertirlos en checkbox para selecionarlos
   */
  useEffect(() => {
    Api.get("/api/permiso")
      .then((response) => {
        //console.log(response);
        const permisos = response.data.map((permiso) => {
          return {
            value: `permiso_${permiso.id}`,
            label: permiso.descripcion.replace("_", " "),
          };
        });
        setInputs([
          ...inputs,
          {
            nombre: "permisos",
            etiqueta: "Permisos",
            tipo: "checkbox",
            opciones: permisos,
            grid: {
              xs: true,
            },
          },
        ]);
      })
      .catch((error) => console.log(handleError(error)));
    return () => {};
    // eslint-disable-next-line
  }, []);

  /**
   * Manipular el objecto para concidir con la especificación del servidor
   * @param {object} data la información a guardar
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const onSave = (data) => {
    //console.log(data);
    const { id, nombre, usuario, contrasena, ...permisos } = data;
    const arrayPermisos = Object.entries(permisos)
      .filter(([, value]) => value === true)
      .map(([key]) => {
        return parseInt(key.split("_")[1], 10);
      });
    const newData = { nombre, usuario, contrasena, permisos: arrayPermisos };
    const editedData = { nombre, usuario, contrasena };
    if (editedData.contrasena === "") {
      delete editedData.contrasena;
    }
    //console.log(newData);
    //console.log(editedData);
    return id ? { admin: editedData, permisos: arrayPermisos } : { ...newData };
  };
  /**
   * Manipula el objecto del registro para concidir con el formulario
   * @param {object} data información del servidor
   */
  const onEdit = (data) => {
    //console.log(data);
    data.contrasena = "";
    const permisosArray = Object.fromEntries(
      data.permisos.map((permiso) => {
        return [`permiso_${permiso.administrador_permiso.permisoId}`, true];
      })
    );
    const newData = { ...data, ...permisosArray };
    //console.log(newData);
    return newData;
  };

  return (
    <Crud
      nombre="Administradores"
      findAllUrl="/api/administrador"
      findByIdUrl="/api/administrador/"
      createUrl="/api/auth/registrar"
      updateUrl="/api/administrador/"
      deleteUrl="/api/administrador/"
      inputs={inputs}
      columnas={[
        {
          nombre: "nombre",
          etiqueta: "Nombre",
          config: { fullWidth: true },
          grid: { xs: 12 },
        },
        {
          nombre: "usuario",
          etiqueta: "Nombre de usuario",
          config: { fullWidth: true },
          grid: { xs: 12 },
        },
      ]}
      onSave={onSave}
      onEdit={onEdit}
    />
  );
}

export default Administrador;
