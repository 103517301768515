/**
 * @module Redux/Reducers
 */
/**
 * Reducer usado por redux para manipular el ejercicio actual
 *
 * @param {object} [state={}]  Estado inicial
 * @param {object} action - parametros para realizar
 * @param {string} action.type - accion a realizar
 * @param {object=} action.payload - nuevo valores para el state
 *
 * @returns {object} state - Estado modificado
 *
 * @version 1.0.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
const ejercicioReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_EJERCICIO":
      return (state = action.payload);
    case "DELETE_EJERCICIO":
      return (state = {});
    default:
      return state;
  }
};

export default ejercicioReducer;
