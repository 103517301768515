import { createMuiTheme } from "@material-ui/core";
import { esES } from "@material-ui/core/locale";
/**
 * @module ThemeProvider
 */
/**
 * Tema personalizado segun las metricas de {@link https://material-ui.com/styles/api/#themeprovider Material UI}
 * @version 1.0.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
export const theme = createMuiTheme(
  {
    typography: {
      fontFamily: '"PT Sans"',
      fontSize: 14,
      fontWeightLight: 400,
      fontWeightRegular: 400,
      fontWeightMedium: 700,
      fontWeightBold: 700,
    },
    palette: {
      type: "light",
      primary: {
        main: "#5a2149",
      },
      secondary: {
        main: "#00b1b0",
        contrastText: "#ffff",
      },
    },
  },
  esES
);
