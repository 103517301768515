import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Api from "../../../Restful/Api";
import Crud from "../CRUD/Crud";
import SelectorEjercicio from "../selectorEjercicio/SelectorEjercicio";

/**
 * Componente que muestra un formulario para manipular aspiraciones
 *
 * @version 1.1.6
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Aspiracion({ ejercicioIds }) {
  /**
   * campo memorizado de ids de ejercicio
   */
  const permanentFilters = useMemo(() => {
    if (ejercicioIds) {
      return { ejercicioIds: [ejercicioIds] };
    } else {
      return {};
    }
  }, [ejercicioIds]);
  /**
   * Hook para manipular los inputs del formulario
   */
  const [inputs, setInputs] = useState([
    { nombre: "id", tipo: "hidden" },
    {
      nombre: "descripcion",
      etiqueta: "Nombre",
      tipo: "textarea",
      config: { required: true, fullWidth: true, rows: 4 },
      grid: {
        xs: 12,
      },
      validacion: {
        required: "El campo es requerido",
        maxLength: {
          value: 500,
          message: "La descripción no puede ser mayor a 500 caracteres",
        },
      },
    },
    {
      nombre: "ejercicioId",
      etiqueta: "Ejercicio",
      tipo: "array",
      opciones: [],
      grid: {
        xs: 12,
      },
      config: {
        required: true,
        fullWidth: true,
      },
    },
    {
      nombre: "archivos",
      etiqueta: "Documentos de guía",
      tipo: "multiple",
      inputs: [
        {
          nombre: "orden",
          etiqueta: "Orden",
          config: {
            type: "number",
          },
        },
        {
          nombre: "descripcion",
          etiqueta: "Descripción",
          validacion: {
            maxLength: {
              value: 40,
              message: "La descripción no puede ser mayor a 40 caracteres",
            },
          },
        },
        {
          nombre: "url",
          etiqueta: "Dirección del archivo (url)",
          validacion: {
            maxLength: {
              value: 249,
              message: "La dirección no puede ser mayor a 249 caracteres",
            },
          },
          grid: { xs: 7 },
        },
      ],
    },
  ]);
  /**
   * Hook para manipular los ejercicios
   */
  const [ejercicios, setEjercicios] = useState([]);

  /**
   * Obtener los ejercios para filtrado y formulario haciendo uso del endpoint findAll
   * seteando el param size a 1000 para evitar paginado
   */
  useEffect(() => {
    Api.get("api/ejercicio", { params: { size: 1000 } }).then((response) => {
      setEjercicios(
        response.data.data.map((ejercicio) => {
          return { value: ejercicio.id, label: ejercicio.nombre };
        })
      );
    });
    return () => {};
  }, []);

  /**
   * Asigna los ejercicios al input del tipo select para poder interactuar en el formulario
   */
  useEffect(() => {
    setInputs((inputs) =>
      inputs.map((input) => {
        if (input.nombre === "ejercicioId") {
          const newInput = {
            ...input,
            opciones: ejercicios,
          };
          return newInput;
        } else {
          return input;
        }
      })
    );
    return () => {};
  }, [ejercicios]);

  /**
   * Manipular el objecto para concidir con la especificación del servidor
   *
   * @param {object} data la información a guardar
   *
   * @version 1.0.1
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const onSave = (data) => {
    const { id, archivos = [], ...inputs } = data;
    const archivosFiltrados = archivos.filter(
      (archivo) => archivo.descripcion && archivo.url && archivo.orden
    );
    return { aspiracion: inputs, archivos: archivosFiltrados };
  };

  return (
    <>
      <SelectorEjercicio ejercicios={ejercicios} />
      <Crud
        nombre="Aspiraciones"
        findAllUrl="api/Aspiracion"
        findByIdUrl="api/Aspiracion/"
        createUrl="api/Aspiracion/"
        updateUrl="api/Aspiracion/"
        deleteUrl="api/Aspiracion/"
        permanentFilters={permanentFilters}
        inputs={inputs}
        columnas={[
          {
            nombre: "descripcion",
            etiqueta: "Nombre",
            grid: { xs: 12 },
            config: { fullWidth: true },
          },
          {
            nombre: "ejercicio",
            etiqueta: (valor) => {
              if (valor !== undefined) {
                return valor.nombre;
              }
              return "Ejercicio";
            },
            onlyColumn: true,
          },
        ]}
        onSave={onSave}
      />
    </>
  );
}

const mapStateToProps = (state) => ({ ejercicioIds: state.ejercicio.id });

export default connect(mapStateToProps)(Aspiracion);
