import React from "react";
import "./App.css";
import Header from "./Components/Header/Header";
import Login from "./Components/Login/Login";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Indicador from "./Components/Ronda/Indicador";
import Rondas from "./Components/Panel/Rondas";
import Ronda from "./Components/Panel/Ronda";
import PrivateRoute from "./Components/Helpers/PrivateRoute";
import { connect } from "react-redux";
import Admin from "./Components/Admin/Admin";
import CustomThemeProvider from "./MuiTheme/CustomThemeProvider";
import Resultados from "./Components/Panel/Resultados";
import Ejercicios from "./Components/Panel/Ejercicios/Ejercicios";
/**
 * Componente principal y enrutador de la pagina
 *
 * @version 2.1.2
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function App({ auth }) {
  return (
    <CustomThemeProvider>
      <div className="App">
        <Header />
        <Router>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route path="/admin">
              <Admin />
            </Route>
            <PrivateRoute auth={auth} exact path="/resultados/:id">
              <Resultados />
            </PrivateRoute>
            <PrivateRoute auth={auth} exact path="/ronda">
              <Ronda />
            </PrivateRoute>
            <PrivateRoute auth={auth} exact path="/ejercicios">
              <Ejercicios />
            </PrivateRoute>
            <PrivateRoute auth={auth} exact path="/rondas">
              <Rondas />
            </PrivateRoute>
            <PrivateRoute auth={auth} path="/votacion-propuestas/:tipo/:idIndicador">
              <Indicador />
            </PrivateRoute>
            <PrivateRoute auth={auth} path="/votacion/:tipo/:idIndicador">
              <Indicador />
            </PrivateRoute>
            <PrivateRoute auth={auth} path="/metas/:tipo/:idIndicador">
              <Indicador />
            </PrivateRoute>
            <PrivateRoute auth={auth} path="*" />
          </Switch>
        </Router>
      </div>
    </CustomThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  auth: state.isLogged,
});

export default connect(mapStateToProps)(App);
