import React, { useState } from "react";
import "./Header.css";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import { AppBar, Button, Menu, MenuItem, Toolbar } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { connect } from "react-redux";
import { isAdmin, loginOut, setUser } from "../../Redux/Actions";

/**
 * Encabezado de la pagina con links correspondientes
 *
 * @param {object} props - Props
 * @param {boolean} props.isLogged - bandera para determinar si el usuario esta logeado o no
 * @param {boolean} props.isAdmin - bandera para determinar si el usuario es administrador o no
 * @param {function} props.logOut - Función a llamar para cerrar sesión
 * @param {string} props.usuario - Nombre del usuario logeado
 *
 * @version 1.3.1
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
export function Header({ isLogged, isAdmin, logOut, usuario, ...props }) {
  /**
   * Hook para manipulacion del menu el usuario
   */
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * Funcion para englobar el ocultar el menu contextual y cerrar sesion en el navegador
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" className="Header" {...props}>
      <Toolbar>
        <div className="Header_icons">
          <a
            href="https://www.facebook.com/ConsejoNL/"
            target="_blank"
            className="Header_icon"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://twitter.com/ConsejoNL"
            target="_blank"
            className="Header_icon"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </a>
          <a
            href="https://www.youtube.com/channel/UCpWCirs_B4SJl0dab-R-U4Q"
            target="_blank"
            className="Header_icon"
            rel="noopener noreferrer"
          >
            <YouTubeIcon />
          </a>
          <a
            href="https://www.linkedin.com/company/consejo-nuevo-le%C3%B3n-para-la-planeaci%C3%B3n-estrat%C3%A9gica/"
            target="_blank"
            className="Header_icon"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </a>
          <a
            href="https://www.instagram.com/ConsejoNL/"
            target="_blank"
            className="Header_icon"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </a>
        </div>
        {isLogged && isAdmin && (
          <div>
            <Button
              aria-label="cuenta admin"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              variant="text"
              startIcon={<AccountCircle />}
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              {usuario}
            </Button>
            <Menu
              id="menu-cuenta-usuario"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={closeMenu}
            >
              <MenuItem
                onClick={() => {
                  closeMenu();
                  logOut();
                }}
              >
                Cerrar sesion
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

const mapStateToProps = (state) => ({
  isLogged: state.isLogged,
  isAdmin: state.isAdmin,
  usuario: state.user.usuario,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => {
      dispatch(setUser({}));
      dispatch(isAdmin(false));
      dispatch(loginOut());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
