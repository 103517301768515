import tokenReducer from "./Token";
import loginReducer from "./LoginStatus";
import { combineReducers } from "redux";
import userReducer from "./userReducer";
import ejercicioReducer from "./ejercicioReducer";
import rondaReducer from "./rondaReducer";
import rondasReducer from "./rondasReducer";
import adminReducer from "./adminReducer";
import dialogsReducer from "./dialogsReducers";
/**
 * @module Redux
 */

/**
 * Combinación de reducers para un solo consumo por parte del Redux
 * @version 1.6.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
const reducers = combineReducers({
  token: tokenReducer,
  isLogged: loginReducer,
  user: userReducer,
  ejercicio: ejercicioReducer,
  ronda: rondaReducer,
  rondas: rondasReducer,
  isAdmin: adminReducer,
  checkDialogs: dialogsReducer,
});

export default reducers;
