import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Skeleton from "../../../Helpers/Skeleton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import AddBoxIcon from "@material-ui/icons/AddBox";
import RefreshIcon from "@material-ui/icons/Refresh";
import Formulario from "../Formulario/Formulario";
import Truncate from "react-truncate";

/**
 * @typedef dato
 * @type {object}
 * @property {string} nombre el nombre del campo
 */

/**
 * Tabla que muestra el listado de elementos
 * @param {Object} props
 * @param {string} props.nombre - Nombre a motrar en la tabla
 * @param {object[]} [props.columnas=[]] - arreglo que describe el numero de columnas, tipo y filtro
 * @param {dato[]} [props.datos =[]] -arreglo que contiene la información a mostrar
 * @param {number} [props.page=1] - pagina a mostrar
 * @param {number} [props.totalItems=1] - total de elementos
 * @param {number} [props.size = 0] - total de elementos a mostrar por pagina
 * @param {boolean} [props.loading = false] - bandera del estado de carga de los datos
 * @param {function} props.changePage - Función a ser llamada cuando se cambia de pagina
 * @param {function} props.addNew - Función a ser llamada cuando se presiona el botón de nuevo
 * @param {function} props.onEdit - Función a ser llamada cuando se presiona el botón de edicion
 * @param {function} props.onDelete - Funóion a ser llamada cuando se presiona el botón de eliminación
 * @param {function} props.changeFiltrado - Función para asignar los filtros actuales
 * @param {function} props.onChangeRowsPerPage -Función para llamar al cambiar las filas por página
 * @param {function} props.onReload - Función para llamar al solicitar una recarga de los datos
 * @param {boolean} props.canCreate bandera para mostrar el boton de creación
 * @param {boolean} props.canEdit bandera para mostrar el boton de edicción
 * @param {boolean} props.canDelete bandera para mostrar el botón de eliminación
 *
 * @version 1.0.9
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */

function Listado({
  nombre,
  columnas = [],
  datos = [],
  page = 0,
  size = 10,
  totalItems = 1,
  loading = false,
  changePage,
  onChangeRowsPerPage,
  addNew,
  onEdit,
  onDelete,
  changeFiltrado,
  onReload,
  canCreate = true,
  canEdit = true,
  canDelete = true,
}) {
  /**
   * Hook para mostrar o no el formulario para filtrar
   */
  const [mostrarFiltrado, setMostrarFiltrado] = useState(false);
  /**
   * Hook para mostrar el dialogo de confirmación de eliminación
   */
  const [showDialog, setShowDialog] = useState(false);
  /**
   * Hook para manpular el id del elemento a eliminar
   */
  const [eliminado, setEliminado] = useState(null);

  /**
   * Función que se encarga de asignar el id del elemento a eliminar y
   * llamar la función onDelete en caso de confirmar el dialogo
   * @param {number=} id El id del elemento a ser eliminado
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleEliminar = (id) => {
    if (id) {
      setShowDialog(true);
      setEliminado(id);
    } else {
      setShowDialog(false);
      onDelete(eliminado);
    }
  };

  /**
   * desregistra y setea valor del filtrado cuando este desaparece
   * @param {boolean} estado El estado del valor mostrarFiltrado
   * @version 1.0.1
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleMostrarFiltrado = (estado) => {
    if (estado) {
      setMostrarFiltrado(estado);
    } else {
      changeFiltrado({});
      setMostrarFiltrado(estado);
    }
  };

  /**
   * Se captura la pagina a cambiar y se envia al padre para su proceso en el servidor
   * @param {*} event El creador del evento de cambio de pagina
   * @param {number} page el numero de pagina a cambiar
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleChangePage = (event, page) => changePage(page);

  return (
    <>
      <Paper>
        <Toolbar>
          {!mostrarFiltrado ? (
            <Typography
              component="div"
              variant="h6"
              style={{ marginRight: "auto" }}
            >
              {nombre}
            </Typography>
          ) : (
            <Formulario
              inputs={columnas.filter((columna) => columna.onlyColumn !== true)}
              saveChanges={changeFiltrado}
              onCancel={() => handleMostrarFiltrado(false)}
              etiquetaBotonGuardado="Filtrar"
              style={{ marginRight: "auto", marginTop: "1rem", width: "100%" }}
            />
          )}
          <Tooltip title="Recargar">
            <span>
              <IconButton
                data-testid="recargar"
                onClick={onReload}
                disabled={loading}
              >
                <RefreshIcon />
              </IconButton>
            </span>
          </Tooltip>
          {canCreate && (
            <Tooltip title="Nuevo registro">
              <span>
                <IconButton
                  data-testid="nuevo-registro"
                  color="primary"
                  onClick={addNew}
                  disabled={loading}
                >
                  <AddBoxIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title="Filtros">
            <span>
              <IconButton
                data-testid="filtros"
                onClick={() => handleMostrarFiltrado(!mostrarFiltrado)}
                disabled={loading}
              >
                <FilterListIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Toolbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columnas
                  .filter((columna) => columna.onlyFilter !== true)
                  .map((columna, index) => (
                    <TableCell key={index}>
                      {columna.etiqueta === undefined
                        ? columna.nombre
                        : typeof columna.etiqueta === "string"
                        ? columna.etiqueta
                        : columna.etiqueta()}
                    </TableCell>
                  ))}
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell align="center" colSpan={columnas.length + 1}>
                    <Skeleton count={2} />
                  </TableCell>
                </TableRow>
              ) : datos.length ? (
                datos.map((dato) => {
                  return (
                    <TableRow key={dato.id}>
                      {columnas
                        .filter((columna) => columna.onlyFilter !== true)
                        .map((columna, index) => {
                          if (
                            columna.nombre.includes("custom") ||
                            dato[columna.nombre] !== undefined
                          ) {
                            const value = dato[columna.nombre];
                            const { etiqueta } = columna;
                            if (typeof etiqueta === "function") {
                              return (
                                <TableCell
                                  style={{ minWidth: "5rem" }}
                                  key={index}
                                >
                                  {columna.nombre.includes("custom") ? (
                                    etiqueta(dato)
                                  ) : (
                                    <Truncate lines={2}>
                                      {etiqueta(value)}
                                    </Truncate>
                                  )}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell
                                  style={{ minWidth: "5rem" }}
                                  key={index}
                                >
                                  <Truncate lines={2}>{value}</Truncate>
                                </TableCell>
                              );
                            }
                          } else {
                            return <TableCell key={index}>NaN</TableCell>;
                          }
                        })}
                      <TableCell>
                        {canEdit && (
                          <Tooltip title="Editar registro">
                            <IconButton onClick={() => onEdit(dato.id)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {canDelete && (
                          <Tooltip title="Eliminar registro">
                            <IconButton
                              data-testid="eliminar-registro"
                              onClick={() => handleEliminar(dato)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={columnas.length + 1}>
                    Sin elementos
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={handleChangePage}
          rowsPerPage={size}
          page={page}
          count={totalItems}
        />
      </Paper>
      {/* Dialogo de confirmación */}
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>¿Desea eliminar este registro?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowDialog(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleEliminar()}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Listado;
