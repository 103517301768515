import axios from "axios";
import { API_URL } from "../config";

/**
 * @module Restful
 */

/**
 * Crear la instacia de AXIOS para consumo del API
 * @version 1.2.1
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
const Api = axios.create({
  baseURL: API_URL,
});
export default Api;
/**
 * Asignar el token de autorización
 * @param {string} token token
 * @version 1.0.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
const setAuthToken = (token) => {
  Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

/**
 * Manipula los diferentes tipos de errores
 * @param {object} error El error devuelto por el servidor
 * @returns {string} El error segun sea el caso
 * @version 1.0.2
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
const handleError = (error) => {
  if (error.response) {
    // Respuesta correcta pero no 200
    if (error.response.data.message) {
      return error.response.data.message;
    } else {
      return error.response.statusText;
    }
  } else if (error.request) {
    // No hay respuesta del servidor
    return "Sin respuesta del servidor";
  } else {
    // Error interno de la llamada
    return error.message;
  }
};

export { setAuthToken, handleError };
