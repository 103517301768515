import { Button, Snackbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { checkDialogIntroduccion, setRonda } from "../../Redux/Actions";
import Api, { handleError } from "../../Restful/Api";
import Lista from "./Lista/Lista";
import Panel from "./Panel";
import UserHeader from "./UserHeader/UserHeader";
import Introduccion from "../Dialogs/Introduccion/Introduccion";
import Documentacion from "../Dialogs/Documentacion";
import { useHistory } from "react-router-dom";
/**
 * Componente que muestra todas las ronda disponibles
 * 
 * @param {object} props
 * @param {number} props.ejercicioId id del ejercicio atual
 * @param {boolean} props.introduccionVista bandera para mostar el modal
 * @param {funcion} props.hideIntroduccion funcion para ocultar el modal
 * @param {funcion} props.setRonda funcion para asignar la ronda actual
 * 
 * @category Votación
 * @version 1.1.1
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Rondas({
  ejercicioId,
  introduccionVista,
  hideIntroduccion,
  setRonda,
}) {
  /**
   * Hook actualizar la dirección del navegador
   */
  const history = useHistory();
  /**
   * Hook para manipular una lista de elementos (rondas)
   */
  const [rondas, setFilaRondas] = useState([]);
  /**
   * Hook para manipular la bandera del estado de carga
   */
  const [loading, setLoading] = useState(false);
  /**
   * Hook para manipular el visinado del Snackbar
   */
  const [message_snackbar, setMessageSnackbar] = useState(false);
  /**
   * Hook para manipular el texto mostrado en el Snackbar
   */
  const [message, setMessage] = useState("");
  /**
   * Hook para manipular el estado de visionado del modal <Documentacion/>
   */
  const [showDocumentacion, setShowDocumentacion] = useState(false);

  /**
   * mostrar un mensaje en el fondo de la pantalla
   * @param {string} message - El mensaje a mostrar
   * @version 1.0.0
   */
  const showSnackbar = (message) => {
    setMessage(message);
    setMessageSnackbar(true);
  };

  /**
   * Resetar todos los elementos almanecenados en Redux, forzando la salida de la página
   * @version 1.0.1
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleSalir = () => {
    history.push("/Ejercicios");
  };
  /**
   * Consumo del API
   */
  useEffect(() => {
    if (ejercicioId) {
      setLoading(true);
      Api.get(`/api/ronda/ejercicio/${ejercicioId}`)
        .then((response) => {
          setLoading(false);
          //console.log(response);
          switch (response.status) {
            case 200:
              setFilaRondas(
                response.data.map((ronda) => {
                  return {
                    id: ronda.id,
                    nombre: ronda.descripcion,
                    estatus: ronda.estatus ? 0 : 2,
                    link: ronda.estatus
                      ? [
                          {
                            texto: "Comenzar",
                            link: () => {
                              setRonda(ronda);
                              history.push("/ronda");
                            },
                            estatus: ronda.estatus ? 0 : 2,
                          },
                        ]
                      : [
                          {
                            texto: "Resultados",
                            link: `/resultados/${ronda.id}`,
                            estatus: ronda.estatus ? 0 : 2,
                          },
                        ],
                    tipo_ronda: ronda.tipo_ronda,
                  };
                })
              );
              //dispatch(setRondas(rondas));
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          setLoading(false);
          showSnackbar(handleError(error));
        });
    }
    // eslint-disable-next-line
  }, [ejercicioId]);

  return (
    <Panel>
      <UserHeader />
      <Lista
        loading={loading}
        nombre='Usted puede entrar y salir del ejercicio cuantas veces sea necesario, un borrador de sus respuestas se almacenará siempre que oprima el botón "regresar al listado" dentro de la aspiración, objetivos y líneas estratégicas. Cuando esté listo para finalizar definitivamente oprima el botón “Salir y enviar respuestas”'
        titulo="Lista de rondas"
        titulo_accion="Estado"
        filas={rondas}
      />
      <div className="bottom_button">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowDocumentacion(true)}
        >
          Documentos de ayuda
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSalir}>
          Regresar a ejercicios
        </Button>
      </div>
      {/* Snackbar section */}
      <Snackbar
        message={message}
        open={message_snackbar}
        autoHideDuration={5000}
        onClose={() => {
          setMessageSnackbar(false);
        }}
      />
      {/* Dialogs */}
      <Introduccion
        flagOpen={introduccionVista}
        handleClose={() => {
          if (introduccionVista) hideIntroduccion();
        }}
      />
      <Documentacion
        flag_open={showDocumentacion}
        handleClose={() => setShowDocumentacion(false)}
      />
    </Panel>
  );
}

const mapStateToProps = (state) => ({
  ejercicioId: state.ejercicio.id,
  introduccionVista: state.checkDialogs.dialog_introduccion,
});

const mapDispatchToProps = (dispatch) => {
  return {
    hideIntroduccion: () => dispatch(checkDialogIntroduccion()),
    setRonda: (data) => dispatch(setRonda(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rondas);
