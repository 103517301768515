import React from "react";
//import css and assets
import "./Panel.css";
//import components
import { Paper } from "@material-ui/core";
/**
 * Wrapper para mostrar elementos con un mismo layout
 * 
 * @param {*} children - Hijo a envolver 
 * 
 * @category Votación
 * @version 1.1.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Panel({ children }) {
  return (
    <div className="PanelUsuario">
      <div className="Login_logo_section">
        <img alt="logo_nuevo_leon" className="Login_logo" src="https://delphiconl.s3.us-east-2.amazonaws.com/front/nuevo_leon_logo.svg" />
      </div>
      <Paper variant="outlined" className="panel_card">
        {children}
      </Paper>
    </div>
  );
}

export default Panel;
