import React from "react";
import { Redirect, Route } from "react-router-dom";
/**
 * Elemento que encapsula un component \<Route/> con opcion de ser visible segun la validación correspondiente
 * 
 * @param {Object} props
 * @param {boolean} props.auth - validación para poder acceder a esta ruta
 * @param {string=} [props.redirect] - ruta a redireccionar si se intenta acceder sin la autorización
 * @param {object} props.children - la ruta en componente \<Route/>
 * 
 * @category Helpers
 * @version 1.1.1
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function PrivateRoute({ auth, redirect = "/", children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirect,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
