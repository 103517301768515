import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Api from "../../../Restful/Api";
import Crud from "../CRUD/Crud";
import SelectorEjercicio from "../selectorEjercicio/SelectorEjercicio";

/**
 * Componente que muestra un formulario para manipular aspiraciones
 *
 * @version 1.2.3
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Usuario({ ejercicioIds }) {
  /**
   * campo memorizado de ids de ejercicio
   */
  const permanentFilters = useMemo(() => {
    if (ejercicioIds) {
      return { ejercicioIds: [ejercicioIds] };
    } else {
      return {};
    }
  }, [ejercicioIds]);

  /**
   * Arreglo que contiene los tipos de sexos para los usuarios
   */
  const sexos = [
    { value: 0, label: "Masculino" },
    { value: 1, label: "Femenino" },
    { value: 2, label: "Otro" },
  ];

  /**
   * Arreglo que tiene los tipos de usuarios
   */
  const tipos = [
    { value: 0, label: "Académico" },
    { value: 1, label: "Funcionario" },
    { value: 2, label: "OSC" },
    { value: 3, label: "Otro" },
  ];

  /**
   * Hook para manipular los inputs del formulario
   */
  const [inputs, setInputs] = useState([
    { nombre: "id", tipo: "hidden" },
    {
      nombre: "nombre",
      etiqueta: "Nombre",
      validacion: {
        required: "El campo es requerido",
        maxLength: {
          value: 150,
          message: "El nombre no puede ser mayor a 150 caracteres",
        },
      },
      config: {
        fullWidth: true,
        required: true,
      },
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      nombre: "email",
      etiqueta: "Email",
      validacion: { required: "El campo es requerido" },
      config: {
        fullWidth: true,
        required: true,
      },
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      nombre: "sexo",
      etiqueta: "Sexo",
      tipo: "array",
      opciones: sexos,
      validacion: { required: "El campo es requerido" },
      config: {
        fullWidth: true,
        required: true,
      },
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      nombre: "tipo_usuario",
      etiqueta: "Tipo",
      tipo: "array",
      opciones: tipos,
      validacion: { required: "El campo es requerido" },
      config: {
        fullWidth: true,
        required: true,
      },
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      nombre: "ejercicios",
      etiqueta: "Ejercicios",
      tipo: "array",
      multiple: true,
      opciones: [],
      config: {
        fullWidth: true,
      },
      grid: { xs: 12 },
    },
    {
      nombre: "aspiraciones",
      etiqueta: "Aspiraciones",
      tipo: "array",
      multiple: true,
      opciones: [],
      config: {
        fullWidth: true,
      },
      grid: { xs: 12 },
    },
    {
      nombre: "objetivos",
      etiqueta: "Objetivos",
      tipo: "array",
      multiple: true,
      opciones: [],
      config: {
        fullWidth: true,
      },
      grid: { xs: 12 },
    },
  ]);

  /**
   * Hook para manipular los ejercicios
   */
  const [ejercicios, setEjercicios] = useState([]);

  /**
   * Hook para manipular los ejercicios del formulario
   */
  const [ejerciciosFormulario, setEjerciciosFormulario] = useState([]);

  /**
   * Obtener los ejercios para filtrado y formulario haciendo uso del endpoint findAll
   * seteando el param size a 1000 para evitar paginado
   */
  useEffect(() => {
    Api.get("api/ejercicio", { params: { size: 1000 } }).then((response) => {
      setEjercicios(
        response.data.data.map((ejercicio) => {
          return { value: ejercicio.id, label: ejercicio.nombre };
        })
      );
      setEjerciciosFormulario(
        response.data.data.map((ejercicio) => {
          return { value: ejercicio.id, label: ejercicio.nombre };
        })
      );
    });
    return () => {};
  }, []);

  /**
   * Hook que actualiza la lista de ejercicios segun el filtro global
   */
  useEffect(() => {
    if (permanentFilters.ejercicioIds) {
      setEjerciciosFormulario(
        ejercicios.filter((ejercicio) => {
          return permanentFilters.ejercicioIds.includes(ejercicio.value);
        })
      );
    }
    return () => {};
    // eslint-disable-next-line
  }, [permanentFilters]);

  /**
   * Hook que actualiza los ejercicios en el formulario
   */
  useEffect(() => {
    setInputs((inputs) =>
      inputs.map((input) => {
        switch (input.nombre) {
          case "ejercicios":
            return {
              ...input,
              opciones: ejerciciosFormulario,
            };

          default:
            return input;
        }
      })
    );
    return () => {};
  }, [ejerciciosFormulario]);

  /**
   * Petición restful para obtener las aspiraciones del servidor
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const getAspiraciones = () => {
    return Api.get("api/Aspiracion", {
      params: { size: 10000, ejercicioIds: permanentFilters.ejercicioIds },
    });
  };

  /**
   * Petición restful para obtener los objetivos del servidor
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const getObjetivos = () => {
    return Api.get("api/Objetivo", {
      params: { size: 10000, ejercicioIds: permanentFilters.ejercicioIds },
    });
  };

  /**
   * Hook para obtener las aspiraciones y los objetivos y asignarlos al formulario
   */
  useEffect(() => {
    Promise.all([getAspiraciones(), getObjetivos()]).then((results) => {
      setInputs((inputs) =>
        inputs.map((input) => {
          switch (input.nombre) {
            case "aspiraciones":
              return {
                ...input,
                opciones: results[0].data.data.map((aspiracion) => {
                  return {
                    value: aspiracion.id,
                    label: aspiracion.descripcion,
                  };
                }),
              };
            case "objetivos":
              return {
                ...input,
                opciones: results[1].data.data.map((objetivo) => {
                  return {
                    value: objetivo.id,
                    label: objetivo.descripcion,
                  };
                }),
              };
            default:
              return input;
          }
        })
      );
    });
    return () => {};
    // eslint-disable-next-line
  }, [permanentFilters]);

  /**
   * Manipular el objecto para concidir con la especificación del servidor
   *
   * @param {object} data la información a guardar
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const onSave = (data) => {
    const { id, ejercicios, aspiraciones, objetivos, ...inputs } = data;
    return { usuario: inputs, ejercicios, aspiraciones, objetivos };
  };

  /**
   * Formatear los arreglos de realaciones para coincidir con el formulario
   *
   * @param {object} data El registro del servidor
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const onEdit = (data) => {
    const { ejercicios, aspiraciones, objetivos, ...otherData } = data;

    const ejerciciosArray = ejercicios.map(
      (ejercicio) => ejercicio.usuario_ejercicio.ejercicioId
    );

    const aspiracionesArray = aspiraciones.map(
      (aspiracion) => aspiracion.usuario_aspiracion.aspiracionId
    );

    const objetivosArray = objetivos.map(
      (objetivo) => objetivo.usuario_objetivo.objetivoId
    );

    return {
      ...otherData,
      ejercicios: ejerciciosArray,
      aspiraciones: aspiracionesArray,
      objetivos: objetivosArray,
    };
  };

  return (
    <>
      <SelectorEjercicio ejercicios={ejercicios} />
      <Crud
        nombre="Usuarios"
        findAllUrl="api/usuario"
        findByIdUrl="api/usuario/"
        createUrl="api/usuario/"
        updateUrl="api/usuario/"
        deleteUrl="api/usuario/"
        permanentFilters={permanentFilters}
        inputs={inputs}
        columnas={[
          {
            nombre: "nombre",
            etiqueta: "Nombre",
            grid: { md: 6, xs: 12 },
            config: { fullWidth: true },
          },
          {
            nombre: "email",
            etiqueta: "Email",
            grid: { md: 6, xs: 12 },
            config: { fullWidth: true },
          },
          {
            nombre: "sexo",
            etiqueta: (valor) => {
              if (valor !== undefined) {
                return sexos.find((sexo) => sexo.value === valor)?.label;
              } else {
                return "Sexo";
              }
            },
            tipo: "array",
            opciones: sexos,
            grid: { md: 6, xs: 12 },
            config: { fullWidth: true },
          },
          {
            nombre: "tipo_usuario",
            etiqueta: (valor) => {
              if (valor !== undefined) {
                return tipos.find((tipo) => tipo.value === valor)?.label;
              } else {
                return "Tipo";
              }
            },
            tipo: "array",
            multiple: true,
            opciones: tipos,
            grid: { md: 6, xs: 12 },
            config: { fullWidth: true },
          },
        ]}
        onSave={onSave}
        onEdit={onEdit}
      />
    </>
  );
}

const mapStateToProps = (state) => ({ ejercicioIds: state.ejercicio.id });

export default connect(mapStateToProps)(Usuario);
