import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { setEjercicio } from "../../../Redux/Actions/index";

/**
 * Estilos
 */
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
}));

/**
 * Selector que permite establecer el ejercicio actual
 *
 * @param {object} props props
 * @param {number|string} props.ejercicioId el id del ejercicio actual
 * @param {function} props.onChange la función para guardar los cambios del selector
 * @param {Array<object>} [props.ejercicios=[]] lista de ejercicios
 *
 * @category Administración
 * @version 1.2.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
const SelectorEjercicio = ({
  ejercicioId,
  onChange,
  ejercicios = [],
  ...props
}) => {
  /**
   * Estilos
   */
  const classes = useStyles();

  const [value, setValue] = useState(ejercicioId);

  /**
   * Asigna el ejercicio actual
   *
   * @param {object} event El evento del selector
   *
   * @version 1.1.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleChange = (event) => {
    const { value } = event.target;
    const newValue = value === "" ? null : value;
    setValue(value);
    onChange(newValue);
  };

  return (
    <Paper className={classes.container}>
      <FormControl variant="filled" fullWidth>
        <InputLabel id="ejercicioActual">Ejercicio actual</InputLabel>
        <Select
          disabled={ejercicios.length ? false : true}
          value={value}
          onChange={handleChange}
          name="ejercicioActual"
          id="ejercicioActual"
          {...props}
        >
          {!props.multiple && <MenuItem value="">Selecionar</MenuItem>}
          {ejercicios.map((ejercicio) => (
            <MenuItem key={ejercicio.value} value={ejercicio.value}>
              {ejercicio.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  );
};

const mapStateToProps = (state, ownProps) => {
  const defaultValue = ownProps.multiple ? [] : "";
  return {
    ejercicioId: state.ejercicio.id ? state.ejercicio.id : defaultValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChange: (data) => dispatch(setEjercicio({ id: data })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectorEjercicio);

export { SelectorEjercicio };
