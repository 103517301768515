import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { default as NumberFormat } from "react-number-format";
/**
 * Obtiene el número más grande de un arreglo
 *
 * @param {number[]} porcentajes - Lista de números a evaluar
 * @returns {number} - el número más alto del arreglo
 *
 * @category Votación
 * @version 1.0.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
const getHigher = (porcentajes) => {
  return Math.max.apply(null, porcentajes);
};
/**
 * Componente que muestra una tabla comparativa de una lista de elementos
 *
 * @param {Object} props - Propiedades
 * @param {string} props.nombre - El titulo de la tabla
 * @param {Array<Object>=} [props.indicadores = []] - Listado de elementos a comparar
 * @param {Array<Object>=} [props.encabezados = []] - Listado de encabezados a mostrar en la tabla
 *
 * @category Votación
 * @version 1.0.0
 * @author Ing. Robeto Alonso De la Garza Mendoza
 */
function Resultado({ nombre, indicadores = [], encabezados = [] }) {
  return (
    /* indicadores.length > 0 && */ <div className="resultado">
      <Typography variant="h6" color="primary" gutterBottom>
        {nombre}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {encabezados.map((encabezado, index) => (
                <TableCell align="center" key={index}>
                  {encabezado}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {indicadores.length === 0 ? (
              <TableRow key={1}>
                <TableCell align="center" colSpan={4}>
                  Sin elementos
                </TableCell>
              </TableRow>
            ) : (
              indicadores.map((indicador, index) => {
                let mayor = getHigher([
                  indicador.porcentajeVotosIncluir,
                  indicador.porcentajeVotosNoIncluir,
                  indicador.porcentajeVotosNoSabe,
                ]);
                return (
                  <TableRow key={index}>
                    <TableCell>{indicador.nombre}</TableCell>
                    <TableCell
                      align="center"
                      className={
                        indicador.porcentajeVotosIncluir === mayor && "mayor"
                      }
                    >
                      <NumberFormat
                        displayType="text"
                        suffix={"%"}
                        decimalScale={0}
                        value={indicador.porcentajeVotosIncluir}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        indicador.porcentajeVotosNoIncluir === mayor && "mayor"
                      }
                    >
                      <NumberFormat
                        displayType="text"
                        suffix={"%"}
                        decimalScale={0}
                        value={indicador.porcentajeVotosNoIncluir}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        indicador.porcentajeVotosNoSabe === mayor && "mayor"
                      }
                    >
                      <NumberFormat
                        displayType="text"
                        suffix={"%"}
                        decimalScale={0}
                        value={indicador.porcentajeVotosNoSabe}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Resultado;
