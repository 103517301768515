import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Panel from "../Panel";
import UserHeader from "../UserHeader/UserHeader";
import Lista from "../../Panel/Lista/Lista";
import Api, { handleError } from "../../../Restful/Api";
import { Button } from "@material-ui/core";
import {
  deleteEjercicio,
  deleteRonda,
  deleteRondas,
  deleteToken,
  deleteUser,
  loginOut,
  setEjercicio,
  uncheckDialogInstruccion,
  uncheckDialogIntroduccion,
} from "../../../Redux/Actions";
import { useHistory } from "react-router-dom";

/**
 * Componente que muestra un listado de ejercicios para el usuario
 *
 * @param {object} props props
 * @param {object} props.user El usuario logeado
 * @param {number} props.user.id El id del usuario
 * @param {function} props.logOutUser Función para cerrar sesión en el navegador
 *
 * @category Votación
 * @version 1.0.1
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
export const Ejercicios = ({ user, logOutUser, setEjercicio }) => {
  /**
   * Hook actualizar la dirección del navegador
   */
  const history = useHistory();
  /**
   * Hook para el estado de carga
   */
  const [loading, setLoading] = useState(false);
  /**
   * Hook para almacenar los ejercicios
   */
  const [ejercicios, setEjercicios] = useState([]);
  /**
   * Hook para obtener los ejercicios para el usuario
   */
  useEffect(() => {
    if (user.id) {
      setLoading(true);
      Api.get(`/api/ejercicio/usuario/${user.id}`)
        .then((response) => {
          setLoading(false);
          setEjercicios(
            response.data.map((ejercicio) => ({
              id: ejercicio.id,
              nombre: ejercicio.nombre,
              estatus: ejercicio.disponible,
              link: [
                {
                  texto: "Comenzar",
                  link: () => {
                    setEjercicio(ejercicio);
                    history.push("/rondas");
                  },
                  estatus: ejercicio.estatus ? 0 : 2,
                },
              ],
            }))
          );
        })
        .catch((error) => {
          setLoading(false);
          console.log(handleError(error));
        });
    }
    return () => {};
    // eslint-disable-next-line
  }, [user.id]);

  return (
    <Panel>
      <UserHeader />
      <Lista
        loading={loading}
        nombre='Usted puede entrar y salir del ejercicio cuantas veces sea necesario, un borrador de sus respuestas se almacenará siempre que oprima el botón "regresar al listado" dentro de la aspiración, objetivos y líneas estratégicas. Cuando esté listo para finalizar definitivamente oprima el botón “Salir y enviar respuestas”'
        titulo="Lista de ejercicios"
        titulo_accion="Estado"
        filas={ejercicios}
      />
      <div className="bottom_button">
        <Button variant="contained" color="secondary" onClick={logOutUser}>
          Salir
        </Button>
      </div>
    </Panel>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setEjercicio: (data) => dispatch(setEjercicio(data)),
    logOutUser: () => {
      dispatch(deleteToken());
      dispatch(deleteUser());
      dispatch(loginOut());
      dispatch(deleteEjercicio());
      dispatch(deleteRonda());
      dispatch(deleteRondas());
      dispatch(uncheckDialogIntroduccion());
      dispatch(uncheckDialogInstruccion());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ejercicios);
