import React from "react";
import "./Lista.css";
import Fila from "../Fila/Fila";
import Skeleton from "../../Helpers/Skeleton";
import { Typography } from "@material-ui/core";
/**
 * Crea un componente de lista con hijos dentro
 * 
 * @param {Object} props - Propiedades
 * @param {boolean} props.loading - bandera para layout de carga
 * @param {Array<Object>} props.filas - Array con los elementos a mostrar
 * @param {string} props.nombre - Titulo a mostrar para la lista
 * @param {string} props.titulo - Encabezado para la columna de texto
 * @param {string} props.titulo_accion -Encabezado para la columna de links
 * 
 * @category Votación
 * @version 1.2.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Lista({ loading, filas, nombre, titulo, titulo_accion }) {
  return (
    <div className="RondaPanel">
      <Typography variant="h5">{nombre}</Typography>
      <div className="indicadores_tabla">
        <Typography variant="h6">{loading ? <Skeleton /> : titulo}</Typography>
        <Typography variant="h6">
          {loading ? <Skeleton /> : titulo_accion}
        </Typography>
      </div>
      {loading ? (
        <Skeleton />
      ) : filas.length ? (
        filas.map((fila) => {
          let subfilas =
            fila.subfilas !== undefined
              ? fila.subfilas.map((subfila) => (
                  <Fila
                    subfila={true}
                    key={subfila.id}
                    nombre={subfila.nombre}
                    estatus={subfila.estatus}
                    link={subfila.link}
                  />
                ))
              : null;
          return (
            <div key={fila.id}>
              <Fila
                nombre={fila.nombre}
                estatus={fila.estatus}
                link={fila.link}
              />
              {subfilas}
            </div>
          );
        })
      ) : (
        <Typography variant="h6">Sin elementos</Typography>
      )}
    </div>
  );
}

export default Lista;
