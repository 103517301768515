import { Button, ButtonGroup, Snackbar } from "@material-ui/core";
import { format, parse } from "date-fns";
import FileSaver from "file-saver";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Api, { handleError } from "../../../Restful/Api";
import Crud from "../CRUD/Crud";
import SelectorEjercicio from "../selectorEjercicio/SelectorEjercicio";

/**
 * Componente que muestra un formulario para manipular rondas
 *
 * @version 1.1.6
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Ronda({ ejercicioIds }) {
  /**
   * campo memorizado de ids de ejercicio
   */
  const permanentFilters = useMemo(() => {
    if (ejercicioIds) {
      return { ejercicioIds: [ejercicioIds] };
    } else {
      return {};
    }
  }, [ejercicioIds]);

  /**
   * Hook para mostrar snackbar
   */
  const [showSnackbar, setShowSnackbar] = useState(false);

  /**
   * Hook para manipular mensaje de snackbar
   */
  const [message, setMessage] = useState("");

  /**
   * Arreglo que contiene los tipos de rondas
   */
  const tipoRondas = [
    { value: 0, label: "Votos y propuestas" },
    { value: 1, label: "Validación" },
    { value: 2, label: "Establecimiento de metas" },
  ];

  /**
   * Hook para manipular los inputs
   */
  const [inputs, setInputs] = useState([
    { nombre: "id", tipo: "hidden" },
    {
      nombre: "descripcion",
      etiqueta: "Descripción",
      validacion: {
        required: "El campo es requerido",
        maxLength: {
          value: 500,
          message: "La descripción no puede ser mayor a 500 caracteres",
        },
      },
      config: { required: true, fullWidth: true },
      grid: { xs: 12, md: 12 },
    },
    {
      nombre: "no",
      etiqueta: "Número de ronda",
      tipo: "number",
      validacion: { required: "El campo es requerido" },
      config: { required: true, fullWidth: true },
      grid: { xs: 12, md: 6 },
    },
    {
      nombre: "tipo_ronda",
      etiqueta: "Tipo de ronda",
      tipo: "array",
      opciones: tipoRondas,
      validacion: { required: "El campo es requerido" },
      config: { required: true, fullWidth: true },
      grid: { xs: 12, md: 6 },
    },
    {
      nombre: "fecha_inicio",
      etiqueta: "Fecha inicio",
      tipo: "text",
      validacion: { required: "El campo es requerido" },
      config: {
        fullWidth: true,
        type: "date",
      },
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      nombre: "fecha_fin",
      etiqueta: "Fecha fin",
      tipo: "text",
      validacion: { required: "El campo es requerido" },
      config: {
        fullWidth: true,
        type: "date",
      },
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      nombre: "ejercicioId",
      etiqueta: "Ejercicio",
      tipo: "array",
      opciones: [],
      validacion: { required: "El campo es requerido" },
      config: { required: true, fullWidth: true },
      grid: { xs: 12, md: 12 },
    },
    {
      nombre: "instrucciones",
      etiqueta: "Instrucciones a mostrar",
      tipo: "editor",
      validacion: { required: "El campo es requerido" },
      grid: {
        xs: 12,
        md: 12,
      },
    },
    {
      nombre: "estatus",
      etiqueta: (valor) => {
        if (valor !== undefined) return valor ? "Activo" : "Inactivo";
        return "Estatus";
      },
      tipo: "switch",
    },
  ]);

  /**
   * Hook para manipular los ejercicios
   */
  const [ejercicios, setEjercicios] = useState([]);

  /**
   * Obtener los ejercios para filtrado y formulario haciendo uso del endpoint findAll
   * seteando el param size a 1000 para evitar paginado
   */
  useEffect(() => {
    Api.get("api/ejercicio", { params: { size: 1000 } }).then((response) => {
      setEjercicios(
        response.data.data.map((ejercicio) => {
          return { value: ejercicio.id, label: ejercicio.nombre };
        })
      );
    });
    return () => {};
  }, []);

  /**
   * Asignar los ejercicios al formulario
   */
  useEffect(() => {
    setInputs((inputs) =>
      inputs.map((input) => {
        if (input.nombre === "ejercicioId") {
          return { ...input, opciones: ejercicios };
        } else {
          return input;
        }
      })
    );
    return () => {};
  }, [ejercicios]);

  /**
   * Manipular el objecto para concidir con la especificación del servidor
   *
   * @param {object} data la información a guardar
   *
   * @version 1.0.1
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const onSave = (data) => {
    const { id, fecha_inicio, fecha_fin, tipo_ronda, ...inputs } = data;

    return {
      ...inputs,
      fechaInicio: parse(fecha_inicio, "yyyy-MM-dd", new Date()).toISOString(),
      fechaFin: parse(fecha_fin, "yyyy-MM-dd", new Date()).toISOString(),
      tipoRonda: tipo_ronda,
    };
  };

  /**
   * Da formato a las fechas
   * @param {object} data El registro del servidor
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const onEdit = (data) => {
    data.fecha_inicio = format(new Date(data.fecha_inicio), "yyyy-MM-dd");
    data.fecha_fin = format(new Date(data.fecha_fin), "yyyy-MM-dd");
    return data;
  };

  /**
   *Manipular los filtros para coincidir con la especificación del servidor
   *
   * @param {object} filtros
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleFilters = (filtros) => {
    const { tipo_ronda, fecha_inicio, ...otherFilters } = filtros;
    return {
      tipoRonda: tipo_ronda,
      fecha_ini: fecha_inicio,
      ...otherFilters,
    };
  };

  const showMessage = (message) => {
    if (message) {
      setMessage(message);
      setShowSnackbar(true);
    } else {
      setShowSnackbar(false);
      setMessage("");
    }
  };

  /**
   * descargar un reporte en formato csv
   *
   * @param {object} ronda la ronda
   * @param {number} tipoReporte el tipo de reporte
   *
   * @version 1.0.1
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const descargarReporte = (ronda, tipoReporte) => {
    showMessage("Descargando reporte...");
    switch (tipoReporte) {
      case 1:
        downloadFile(
          `api/reporte/totalVotos/ronda/${ronda.id}`,
          `indicadores-elegidos-${normalizarTexto(ronda.descripcion)}.csv`
        );
        break;
      case 2:
        downloadFile(
          `api/reporte/indicadoresPropuestos/ronda/${ronda.id}`,
          `indicadores-propuestos-${normalizarTexto(ronda.descripcion)}.csv`
        );
        break;
      case 3:
        downloadFile(
          `api/reporte/actividadUsuariosVotos/ronda/${ronda.id}`,
          `actividad-${normalizarTexto(ronda.descripcion)}.csv`
        );
        break;
      case 4:
        downloadFile(
          `api/reporte/totalMetas/ronda/${ronda.id}`,
          `total-metas-${normalizarTexto(ronda.descripcion)}.csv`
        );
        break;
      case 5:
        downloadFile(
          `api/reporte/actividadUsuariosMetas/ronda/${ronda.id}`,
          `actividad-${normalizarTexto(ronda.descripcion)}.csv`
        );
        break;
      case 6:
        downloadFile(
          `api/reporte/totalVotosDesglosado/ronda/${ronda.id}`,
          `lista-votos-${ronda.descripcion}.csv`
        );
        break;
      case 7:
        downloadFile(
          `api/reporte/totalMetasDesglosado/ronda/${ronda.id}`,
          `lista-metas-${ronda.descripcion}.csv`
        );
        break;
      default:
        break;
    }
  };

  /**
   * Quitar espacios en blanco
   *
   * @param {string} texto Texto a normalizar
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const normalizarTexto = (texto) => {
    return texto.replace(" ", "-");
  };

  /**
   * Descarga el archivo
   *
   * @param {string} url direccion donde descarga el archivo
   * @param {string} nombreArchivo nombre del archivo
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const downloadFile = (url, nombreArchivo) => {
    Api.get(url, { responseType: "blob", timeout: 30000 })
      .then((response) => {
        FileSaver.saveAs(response.data, nombreArchivo);
      })
      .catch(async (error) => {
        try {
          const plainText = await fileParser(error.response.data);
          const json = JSON.parse(plainText);
          showMessage(
            handleError({ response: { ...error.response, data: json } })
          );
        } catch (error) {
          console.log(error);
        }
      });
  };

  /**
   * Leer un objecto binario a texto plano
   *
   * @param {object} file Blob
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const fileParser = (file) => {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort(reject(new Error("Un problema al obtener el error")));
      };
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.readAsText(file);
    });
  };

  return (
    <>
      <SelectorEjercicio ejercicios={ejercicios} />
      <Crud
        nombre="Rondas"
        findAllUrl="api/ronda"
        findByIdUrl="api/ronda/"
        createUrl="api/ronda/"
        updateUrl="api/ronda/"
        deleteUrl="api/ronda/"
        permanentFilters={permanentFilters}
        inputs={inputs}
        columnas={[
          {
            nombre: "descripcion",
            etiqueta: "Descripción",
            config: { fullWidth: true },
            grid: { xs: 12 },
          },
          {
            nombre: "ejercicioId",
            etiqueta: (valor) => {
              if (valor !== undefined) {
                return ejercicios.find((ejercicio) => ejercicio.value === valor)
                  ?.label;
              } else {
                return "Ejercicio";
              }
            },
            onlyColumn: true,
            // tipo: "array",
            // multiple: true,
            // opciones: ejercicios,
            // config: { fullWidth: true },
            // grid: { xs: 12 },
          },
          {
            nombre: "no",
            etiqueta: "Número de ronda",
            tipo: "number",
            grid: { md: 6, xs: 12 },
            config: { fullWidth: true },
          },
          {
            nombre: "tipo_ronda",
            etiqueta: (valor) => {
              if (valor !== undefined) {
                return tipoRondas.find((tipoRonda) => tipoRonda.value === valor)
                  ?.label;
              } else {
                return "Tipo de ronda";
              }
            },
            tipo: "array",
            opciones: tipoRondas,
            grid: { md: 6, xs: 12 },
            config: { fullWidth: true },
          },
          {
            nombre: "fecha_inicio",
            etiqueta: (valor) => {
              if (valor === undefined) {
                return "Fecha inicio";
              } else {
                return format(new Date(valor), "dd-MM-yyyy");
              }
            },
            grid: { md: 6, xs: 12 },
            config: {
              type: "date",
              fullWidth: true,
            },
          },
          {
            nombre: "fecha_fin",
            etiqueta: (valor) => {
              if (valor === undefined) {
                return "Fecha fin";
              } else {
                return format(new Date(valor), "dd-MM-yyyy");
              }
            },
            grid: { md: 6, xs: 12 },
            config: {
              type: "date",
              fullWidth: true,
            },
          },
          {
            nombre: "custom-1",
            onlyColumn: true,
            etiqueta: (ronda) => {
              if (ronda !== undefined) {
                switch (ronda.tipo_ronda) {
                  case 0:
                    return (
                      <ButtonGroup
                        size="small"
                        orientation="vertical"
                        color="secondary"
                        variant="text"
                      >
                        {/* Reporte 1 */}
                        <Button onClick={() => descargarReporte(ronda, 1)}>
                          Indicadores
                        </Button>
                        {/* Reporte 2 */}
                        <Button onClick={() => descargarReporte(ronda, 2)}>
                          Propuestas
                        </Button>
                        {/* Reporte 3 */}
                        <Button onClick={() => descargarReporte(ronda, 3)}>
                          Monitoreo
                        </Button>
                        {/* Reporte 6 */}
                        <Button onClick={() => descargarReporte(ronda, 6)}>
                          votos
                        </Button>
                      </ButtonGroup>
                    );
                  case 1:
                    return (
                      <ButtonGroup
                        size="small"
                        orientation="vertical"
                        color="secondary"
                        variant="text"
                      >
                        {/* Reporte 1 */}
                        <Button onClick={() => descargarReporte(ronda, 1)}>
                          Indicadores
                        </Button>
                        {/* Reporte 3 */}
                        <Button onClick={() => descargarReporte(ronda, 3)}>
                          Monitoreo
                        </Button>
                        {/* Reporte 6 */}
                        <Button onClick={() => descargarReporte(ronda, 6)}>
                          votos
                        </Button>
                      </ButtonGroup>
                    );

                  case 2:
                    return (
                      <ButtonGroup
                        size="small"
                        orientation="vertical"
                        color="secondary"
                        variant="text"
                      >
                        {/* Reporte 4 */}
                        <Button onClick={() => descargarReporte(ronda, 5)}>
                          Metas
                        </Button>
                        {/* Reporte 5 */}
                        <Button onClick={() => descargarReporte(ronda, 4)}>
                          Participantes
                        </Button>
                        {/* Reporte 7 */}
                        <Button onClick={() => descargarReporte(ronda, 7)}>
                          Detalles
                        </Button>
                      </ButtonGroup>
                    );

                  default:
                    break;
                }
              } else {
                return "Reportes";
              }
            },
          },
          {
            nombre: "estatus",
            etiqueta: (valor) => {
              if (valor !== undefined) return valor ? "Activo" : "Inactivo";
              return "Estatus";
            },
            tipo: "switch",
            config: {
              defaultChecked: true,
            },
          },
        ]}
        onEdit={onEdit}
        onSave={onSave}
        onFilter={handleFilters}
      />
      <Snackbar
        open={showSnackbar}
        message={message}
        autoHideDuration={1000}
        onClose={() => showMessage()}
      />
    </>
  );
}
const mapStateToProps = (state) => ({ ejercicioIds: state.ejercicio.id });

export default connect(mapStateToProps)(Ronda);
