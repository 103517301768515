import React from "react";
import "./Skeleton.css";
/**
 * Elemento de carga asincrona para mostrar y evitar la frustración del usuario
 * 
 * @param {Object} props - props
 * @param {string} [props.height=20px] - css-rule para height
 * @param {string} [props.marginTop=.5rem] - css-rule para margin-top
 * @param {string} [props.marginBottom=.5rem] - css-rule para margin-bottom
 * @param {number} [props.count = 1] - La cantidad de elementos a mostrar por skeleton
 * @see https://material-ui.com/es/components/skeleton/
 * 
 * @category Helpers
 * @version 1.1.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Skeleton({
  height = "20px",
  marginTop = ".5rem",
  marginBottom = ".5rem",
  count = 1,
}) {
  const configstyle = {
    height,
    marginTop,
    marginBottom,
  };
  return Array(count)
    .fill()
    .map((e, index) => (
      <div
        data-testid="skeleton"
        key={index}
        className="skeleton"
        style={configstyle}
      ></div>
    ));
}

export default Skeleton;
