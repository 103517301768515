import React from "react";
import "../Dialog.css";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Button,
  DialogActions,
  Typography,
} from "@material-ui/core";
/**
 * Funcion para habilitar animación de entrada vertical
 * 
 * @category Dialogs
 * @version 1.0.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
/**
 * Modal que muestra el aviso de privacidad
 * 
 * @param {Object} props - Propiedades
 * @param {boolean} props.flag_open - bandera para mostrar o no el modal
 * @param {function} props.handleClose - Callback para cerrar el modal
 * 
 * @category Dialogs
 * @version 1.1.1
 * @author Ing. Roberto Alonso De la Garza
 */
function AvisoPrivacidad({ flagOpen, handleClose }) {
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={flagOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle>AVISO DE PRIVACIDAD</DialogTitle>
      <DialogContent>
        <div className="informacion_dialogo"><Typography variant="body1" paragraph align="justify">
        El presente Aviso de Privacidad es aplicable solamente para el sitio
        web inteligencia.conl.mx, en lo sucesivo identificado como el PORTAL
        </Typography>
        <Typography variant="body1" paragraph align="justify">
        Para efectos del presente Aviso de Privacidad, se considerará USUARIO
        a las personas convocadas a participar en el ejercicio “Identificación
        de indicadores y establecimiento de metas del Plan Estratégico de
        Nuevo León” por medio de una invitación directa. El USUARIO acepta
        plena e incondicionalmente todas y cada una de las disposiciones del
        Aviso de Privacidad y las Reglas del uso del Portal, que juntos
        conforman el “Aviso Legal”, publicados en el Portal. El presente Aviso
        de Privacidad se remitirá para todo lo definido aquí a los Términos y
        Condiciones.
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Consejo Nuevo León, A.C., se reserva el derecho de efectuar en
        cualquier momento modificaciones o actualizaciones al Aviso de
        Privacidad para la atención de novedades legislativas, políticas
        internas o nuevos requerimientos. Dichas modificaciones se notificarán
        a través del Portal y envió por correo electrónico.
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        El USUARIO reconoce que ingresa y proporciona su información personal,
        (“Información Personal” o “Datos personales”), al Portal o a Consejo
        Nuevo León, A.C., de manera libre y voluntaria aceptando para ello los
        términos y condiciones del Aviso de Privacidad.
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Consejo Nuevo León, A.C., con domicilio ubicado en avenida Lázaro
        Cárdenas 435, interior 1040, zona Loma Larga Oriente, C. P. 66266, San
        Pedro Garza García, Nuevo León, México, reconoce expresamente el
        derecho de protección a los datos personales, consagrado en el
        artículo 16 constitucional, y en la Ley Federal de Protección de Datos
        Personales en Posesión de Particulares, (“LFPDPPP”). Asimismo se hace
        responsable de la Información Personal o Datos personales que recabe
        por sí mismo atendiendo los principios de licitud, consentimiento,
        información, calidad, finalidad, lealtad, proporcionalidad y
        responsabilidad. Los datos proporcionados en este sitio web serán
        identificados como “Información Confidencial”.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
        FINALIDADES
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        La información Personal del USUARIO será utilizada para: (i) Asignar
        información específica del ejercicio de acuerdo con su ámbito de
        especialización; (ii) Cualquier otro tratamiento de Información
        Personal que resulte necesario, adecuado y conveniente para llevar a
        cabo los objetivos de la Plataforma para la identificación de
        indicadores y la definición de metas del Plan Estratégico de Nuevo
        León.
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Los Datos Personales se recaban a través del Portal cuando el USUARIO
        los proporciona directamente por diversos medios, o bien, a través de
        cualquier fuente permitida por la ley. Dichos Datos Personales serán
        los siguientes: (i) nombre completo y (ii) dirección de correo
        electrónico.
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        El USUARIO reconoce y acepta que Consejo Nuevo León, A.C. podrá
        utilizar un sistema de seguimiento y reproducción mediante la
        utilización de cookies (las “Cookies”) y web beacon (“web beacon”).
        Las cookies son archivos temporales que se instalan en el equipo del
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        USUARIO y el web beacon es un código o imagen electrónica
        transparente, ambos permiten a Consejo Nuevo León, A.C. medir patrones
        de tráfico en el Portal, así como conocer el comportamiento y la
        demografía de los USUARIOS, con el fin de analizar las páginas
        navegadas y las búsquedas realizadas.
        </Typography>
        
        <Typography component="div" variant="body1" paragraph align="justify">
        Los datos personales que obtenemos mediante las Cookies y el web
        beacon son:
        <ul>
        <li>Nombres de usuario de una sesión.</li>
        
        <li>Tipo de navegador del usuario.</li>
        
        <li>Tipo de sistema operativo del usuario.</li>
        
        <li>
        Fecha y hora del inicio y final de una sesión de un usuario.
        </li>
        </ul>
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        EL USUARIO reconoce que la instalación, permanencia y existencia de
        las cookies puede ser cancelada y/o eliminada de su equipo cuando el
        USUARIO así lo desee.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
        TRANSFERENCIA
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Los Datos Personales del USUARIO pueden ser tratados dentro y fuera de
        la República Mexicana.
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Consejo Nuevo León, A.C., podrá transferir sus Datos Personales a
        terceros, como lo son los (i) presidentes de comisión del Consejo.
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Si el USUARIO no manifiesta su oposición para que sus Datos Personales
        sean transferidos, se entenderá que ha otorgado su consentimiento para
        ello.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
        CONTACTO
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        El USUARIO tiene derecho de acceder a sus Datos Personales y a los
        detalles de su tratamiento, así como rectificarlos en caso de ser
        inexactos o incompletos; cancelarlos cuando considere que no se
        requieren para alguna de las finalidades señaladas en el presente
        Aviso de Privacidad, estén siendo utilizados para finalidades no
        consentidas, o haya concluido la relación contractual o de servicio, o
        bien, oponerse al tratamiento de los mismos para fines específicos.
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Consejo Nuevo León, A.C., a través de su responsable en el tratamiento
        de Datos Personales: Lic. Tatiana Fraga Diez, pone a su
        disposición la siguiente dirección de correo electrónico red@conl.mx,
        así como el teléfono (81) 20895580, para atender cualquier solicitud
        relacionada derechos de acceso, rectificación, cancelación revocación
        del consentimiento para el tratamiento de Datos Personales mismas que
        serán atendidas conforme a los plazos establecidos en la LFPDPPP.
        Dicha solicitud deberá incluir: (i) el derecho (ARCO) que desea
        ejercer, (ii) nombre y/o razón social, RFC y teléfono de contacto,
        (iii) documentos que acrediten la personalidad (copia de
        identificación oficial o poder en escritura pública); se dará trámite
        y respuesta a la solicitud, mediante correó electrónico o al domicilio
        señalado, dentro de los 2 (dos) días posteriores a la recepción de la
        solicitud.
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Si el USUARIO considera que su derecho de protección de Datos
        Personales ha sido lesionado por alguna conducta del Consejo Nuevo
        León A. C., podrá interponer la queja o denuncia correspondiente ante
        el INAI a través del Portal www.inai.org.mx
        </Typography>
        
        <Typography variant="h6" gutterBottom>
        REGLAS DE USO DEL PORTAL
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Las siguientes Reglas de Uso del Portal es aplicable al sitio web
        inteligencia.conl.mx en lo sucesivo identificado indistintamente como
        el “Portal”, propiedad de Consejo Nuevo León, A. C. (“Consejo Nuevo
        León”).
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Para efectos de las presentes Reglas de Uso del Portal, se considerará
        USUARIO a las personas convocadas a participar en el ejercicio
        “Identificación de indicadores y establecimiento de metas del Plan
        Estratégico de Nuevo León” por medio de una invitación directa. El
        USUARIO acepta plena e incondicionalmente tanto las Reglas de Uso del
        Portal como el Aviso de Privacidad, que juntos conforman el “Aviso
        Legal”, publicados de manera permanente en el Portal. Consejo Nuevo
        León se reserva el derecho de efectuar en cualquier momento
        modificaciones o actualizaciones al Aviso Legal para la atención de
        novedades legislativas, políticas internas o nuevos requerimientos
        para la prestación u ofrecimiento de nuestros servicios o productos.
        Dichas modificaciones se notificarán a través del Portal.
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Consejo Nuevo León es una institución legalmente constituida cuyo
        objeto es fomentar, colaborar y contribuir en general con los
        Gobiernos federal, estatal o municipal, en materia de desarrollo
        económico y generación de riqueza; desarrollo humano y social,
        educación, salud, arte y cultura, valores y participación ciudadana;
        desarrollo sustentable, urbano, infraestructura y transporte;
        seguridad integral y justicia; transparencia, combate a la corrupción
        y cultura de la legalidad, Gobierno eficaz y mejora regulatoria; entre
        otros temas de interés comprendidos en la Ley de Planeación
        Estratégica del Estado de Nuevo León.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
        USO DEL CONTENIDO
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        El USUARIO reconoce que el uso del Portal está sujeto al Aviso Legal.
        Dicho Aviso Legal tiene carácter obligatorio y vinculante para el
        USUARIO. En caso de que el USUARIO no esté de acuerdo con el Aviso
        Legal en su totalidad deberá abstenerse de utilizar, operar y/o
        visitar el Portal.
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Queda expresamente prohibido: (i) utilizar el Portal y los servicios
        para propósitos ilegales, inmorales, obscenos o prohibidos por la
        costumbre y la legislación aplicable y/o por el presente Aviso Legal;
        (ii) provocar modificaciones, alteraciones y/o supresiones, realizados
        por medios electrónicos o de cualquier otra forma, empleando recursos
        lícitos o ilícitos, que puedan interferir en la administración u
        operación del Portal y/o en los servicios prestados por Consejo Nuevo
        León.
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        El USUARIO será responsable de los daños y perjuicios que se causen a
        Consejo Nuevo León derivados de sus actos o de aquellos que provengan
        de sus funcionarios, empleados, factores, dependientes y/o persona
        física o moral asociada, subsidiaria y/o filial.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
        PROPIEDAD INTELECTUAL
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Consejo Nuevo León cuenta con la titularidad y propiedad exclusiva de
        los derechos otorgados por las leyes vigentes y/o tratados
        internacionales inherentes a la propiedad intelectual, para todas las
        marcas, avisos comerciales y programas de computación, mediante los
        cuales comercializa y presta sus servicios, incluyendo pero sin
        limitar: (i) la propiedad industrial sobre los títulos de registros de
        marcas y avisos comerciales otorgados por el Instituto Mexicano de la
        Propiedad Industrial; (ii) los derechos de autor respecto del
        programa(s) de computación, sistema(s) y/o sus derivados, otorgados
        por el Instituto Nacional de Derechos de Autor; y (iii) todos los
        desarrollos, know how, signos distintivos y/o bienes o derechos que le
        correspondan en el ámbito de la propiedad intelectual por derecho
        propio; todo lo anterior identificado en lo sucesivo como “Propiedad
        Intelectual”.
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Consejo Nuevo León no concede ninguna licencia o autorización de uso
        de ninguna clase sobre la Propiedad Intelectual, salvo acuerdo expreso
        por escrito, por lo que el USUARIO reconoce que: (i) no tiene derecho
        alguno sobre la Propiedad Intelectual, por lo que se obliga a respetar
        en todo momento los derechos que detenta Consejo Nuevo León sobre
        esta; (ii) no podrá modificar, alterar, suprimir, copiar o reproducir
        ya sea total o parcialmente, incluyendo pero sin limitar, el contenido
        informativo generado por Consejo Nuevo León, la Propiedad Intelectual
        y/o cualquier indicación contenida en el Portal.
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        En caso que el USUARIO transmita a Consejo Nuevo León cualquier
        información, incluyendo pero sin limitar, programas, aplicaciones,
        software o en general cualquier documento o archivo que requieran ser
        licenciados, en lo sucesivo “Datos Compartidos”, el USUARIO se obliga
        a: (i) otorgar a Consejo Nuevo León una autorización de uso, gratuita,
        no exclusiva y libre de regalías, sobre los Datos Compartidos; y (ii)
        sacar en paz y salvo a Consejo Nuevo León y/o sus filiales, de
        cualquier acción, demanda o reclamación por cualquier violación de
        propiedad intelectual derivada del uso de Datos Compartidos que el
        propio USUARIO envíe a Consejo Nuevo León.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
        LÍMITE DE RESPONSABILIDAD
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Hasta el máximo permitido por las leyes aplicables, Consejo Nuevo León
        no será responsable, en ningún caso, por cualquier daño directo,
        indirecto, especial, incidental o consecuencial que en cualquier
        forma, se deriven o se relacionen con: (i) el uso o ejecución del
        Portal; (ii) el retraso o falta de disponibilidad de uso del Portal;
        (iii) la proveeduría o falta de proveeduría de servicios de
        cualesquier información, gráficos contenidos o publicados en o a
        través del Portal (iv) de la actualización o falta de actualización de
        la información; (v) que la información haya sido alterada o
        modificada, en todo o en parte, luego de haber sido incluida en el
        Portal; (vi) cualquier otro aspecto o característica de la información
        contenida o publicada en el Portal o a través de las ligas que
        eventualmente se incluyan en el mismo; (vii) la proveeduría o falta de
        proveeduría de los servicios.
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Consejo Nuevo León no es ni será responsable del uso, contenido,
        veracidad, calidad o precisión de la información contenida en el
        Portal, incluyendo pero sin limitar, la publicada por Consejo Nuevo
        León y/o la proporcionada por el USUARIO a través de cualquier medio.
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        El USUARIO libera expresamente a Consejo Nuevo León de cualquier
        responsabilidad derivada de lo anterior y se obliga a sacar en paz y
        salvo a Consejo Nuevo León, cubriendo los daños y perjuicios que le
        sean aplicables de las reclamaciones que: (i) pudiera llegar a hacerle
        cualesquier tercero; (ii) surjan de cualquier requerimiento,
        reclamación y/o procedimiento judicial y/o administrativo derivado del
        incumplimiento de obligaciones del USUARIO reglamentadas en la
        legislación vigente y/o aquellas que pudiera adquirir o haber
        adquirido.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
        MODIFICACIONES
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Consejo Nuevo León podrá unilateralmente, en cualquier momento, para
        cumplir con la legislación vigente y/o cuando considere conveniente,
        realizar correcciones, adiciones, mejoras o modificaciones al
        contenido, presentación, información, servicios, áreas, bases de datos
        y demás elementos del Portal. El USUARIO reconoce que no tiene derecho
        a ninguna reclamación, indemnización y/o pago de los daños y
        perjuicios causados por los cambios efectuados.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
        LEGISLACIÓN APLICABLE Y JURISDICCIÓN
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Este instrumento será regido e interpretado de acuerdo con las leyes
        de los Estados Unidos Mexicanos. Cualquier controversia que surja de
        la interpretación o ejecución del Aviso Legal, se someterá a la
        jurisdicción exclusiva de los tribunales competentes en la ciudad de
        Monterrey, Nuevo León, México por lo que el USUARIO renuncia a
        cualquier otra jurisdicción que sea aplicable en virtud de sus
        domicilios presentes o futuros o cualquier otra circunstancia.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
        INFORMACIÓN
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Consejo Nuevo León podrá recibir comunicaciones relacionadas a la
        prestación de sus servicios y/o al Aviso Legal, en la dirección de
        correo electrónico: red@conl.mx
        </Typography>
        
        <Typography variant="body1" paragraph align="justify">
        Consejo Nuevo León seleccionará el medio de comunicación que considere
        apropiado, incluyendo el uso de mensajes de datos, para responder a
        cualquier solicitud de información que deba atender conforme a
        derecho.
        </Typography></div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={handleClose}>
          cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AvisoPrivacidad;
