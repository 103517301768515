import { ThemeProvider } from "@material-ui/core";
import React from "react";
import { theme } from "./theme";
/**
 * @module ThemeProvider
 */
/**
 * Elemento que proporciona acceso aun tema global
 *
 * @param {*} props
 * @param {*} props.children - El elemento hijo
 *
 * @version 1.0.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function CustomThemeProvider({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default CustomThemeProvider;
