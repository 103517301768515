import {
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useState } from "react";
import { get } from "react-hook-form";

/**
 * Elemento que permite agregar dinamicamente información a un input
 * @param {object} props props
 * @param {object[]} props.inputs Los campos a guardar como un objecto en el arreglo
 * @param {object[]} props.datos Los datos a mostrar en forma de listado
 * @param {string} props.nombre El texto a mostrar como principal en el listado
 * @param {string|function} props.etiqueta El campo a mostrar como secondario en el listado
 * @param {function} props.register Hook de React Hook form para poder obtener un arreglo de los inputs
 * @param {function} props.errors Objeto con los errores de validación
 *
 * @category Administración
 * @subcategory Crud
 * @version 1.1.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function MultiInputField({
  nombre,
  etiqueta,
  inputs = [],
  datos = [],
  register,
  errors = {},
  ...props
}) {
  /**
   * Hook para manipular el listado de registros en el campo
   */
  const [multiInputs, setMultiInputs] = useState(datos);
  /**
   * Elimina un bloque de inputs del campo
   *
   * @param {number} index el indice del bloque de inputs a eliminar
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleDeleteItem = (index) => {
    const newDatos = [...multiInputs];
    newDatos.splice(index, 1);
    setMultiInputs(newDatos);
  };
  /**
   *Agregar un nuevo set de inputs al campo
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleAddItem = () => {
    const newItem = Object.fromEntries(
      inputs.map((input) => [input.nombre, ""])
    );
    setMultiInputs([...multiInputs, newItem]);
  };

  /**
   * obtiene el valor dependiendo del nombre del input
   *
   * @param {object} dato el objecto que contiene los datos del registro
   * @param {object} input El input a llenar con texto
   * @returns {string} el valor segun la llave del input
   *
   * @version 1.0.0
   * @author Ing. Roberto Alonso De la Garza Mendoza
   */
  const handleDefaultValue = (dato, input) => {
    const datoArray = Object.entries(dato);

    const datoValue = datoArray.find(([key]) => key === input.nombre);
    if (datoValue) {
      return datoValue[1];
    } else {
      return "";
    }
  };

  return (
    <Grid
      container
      spacing={1}
      style={{ marginBottom: "1rem", padding: ".5rem" }}
      {...props}
    >
      <Grid item xs={12}>
        <Typography component="div" variant="h6">
          {etiqueta === undefined
            ? nombre
            : typeof etiqueta === "string"
            ? etiqueta
            : etiqueta()}
        </Typography>
      </Grid>
      {multiInputs?.map((dato, index) => {
        return (
          <Grid item key={index} container spacing={2}>
            {inputs.map((input) => {
              const name = `${nombre}[${index}].${input.nombre}`;
              return (
                <Grid key={name} item xs {...input.grid}>
                  <TextField
                    fullWidth
                    type="text"
                    variant="filled"
                    inputRef={register(input.validacion)}
                    id={name}
                    name={name}
                    label={input.etiqueta}
                    defaultValue={handleDefaultValue(dato, input)}
                    error={get(errors, name) ? true : false}
                    helperText={
                      get(errors, `${name}.message`) &&
                      errors[nombre][index][input.nombre].message
                    }
                    {...input.config}
                  />
                </Grid>
              );
            })}
            <Grid item xs>
              <Tooltip title="Eliminar entrada">
                <IconButton onClick={() => handleDeleteItem(index)}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="button"
          onClick={handleAddItem}
        >
          Agregar
        </Button>
      </Grid>
    </Grid>
  );
}

export default MultiInputField;
