import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Slide,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Api, { handleError } from "../../Restful/Api";
import Skeleton from "../Helpers/Skeleton";
/**
 * Funcion para habilitar animación de entrada vertical
 * 
 * @category Dialogs
 * @version 1.0.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
/**
 * Modal para mostrar una lista de documentos a descargar
 * 
 * @param {Object} props - Propiedades
 * @param {boolean} props.flag_open - bandera para mostrar o no el modal
 * @param {function} props.handleClose - Callback para cerrar el modal
 * 
 * @category Dialogs
 * @version 1.1.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Documentacion({ flag_open, handleClose, ejercicioId }) {
  /**
   * Hook para el estado de carga
   */
  const [loading, setLoading] = useState(true);
  /**
   * Hook para la lista de documentos a mostrar
   */
  const [documentos, setDocumentos] = useState([]);
  /**
   * Funcion asincrona que consume el API
   * @version 1.0.0
   */
  useEffect(() => {
    if (ejercicioId) {
      Api.get(`/api/archivo/ejercicio/${ejercicioId}`)
        .then((response) => {
          setLoading(false);
          switch (response.status) {
            case 200:
              setDocumentos(response.data.archivos);
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(handleError(error));
        });
    }
  }, [ejercicioId]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={flag_open}
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle>Documentación</DialogTitle>
      <DialogContent>
        {loading ? (
          <Skeleton count={6} marginTop="1.5rem" />
        ) : (
          <List>
            {documentos.map((documento, index) => (
              <div key={index}>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar>
                      <DescriptionOutlinedIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={documento.descripcion} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="descargar"
                      onClick={() => {
                        window.open(documento.url);
                      }}
                    >
                      <CloudDownloadIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider variant="inset" component="li" />
              </div>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={handleClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  ejercicioId: state.ejercicio.id,
});

export default connect(mapStateToProps)(Documentacion);
