import { Typography } from "@material-ui/core";
import React from "react";
import "../Dialog.css";
/**
 * Mensaje de agradecimiento embebido en el componente Instruccion.js
 * 
 * @category Dialogs
 * @version 1.0.1
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Agradecimiento() {
  return (
    <div className="informacion_dialogo">
      <Typography
        component="div"
        variant="h3"
        align="center"
        gutterBottom
        color="primary"
      >
        Gracias por participar
      </Typography>
    </div>
  );
}

export default Agradecimiento;
