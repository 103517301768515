import React from "react";
import "./Fila.css";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
/**
 * Crear los botones con links
 * 
 * @param {string | string[]} link -el link o lista de links
 * @param {boolean} estatus - estado de elemento para setear el estilo del boton
 * @returns {object} - Un elemento \<Link/> con un boton adentro
 * 
 * @category Votación
 * @version 1.1.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
const getLinks = (link, estatus) => {
  if (typeof link === "string")
    return (
      <Link className="link_router" to={link}>
        <button
          className={`Button ${estatus === 2 && "Completed"} ${
            estatus === 1 && "Proceso"
          }`}
        >
          {estatus === 2
            ? "Completado"
            : estatus === 1
            ? "En proceso"
            : "Comenzar"}
        </button>
      </Link>
    );
  else if (Array.isArray(link))
    return link.map((l, index) =>
      typeof l.link === "string" ? (
        <Link key={index} className="link_router" to={l.link}>
          <button
            className={`Button ${l.estatus === 2 && "Completed"} ${
              l.estatus === 1 && "Proceso"
            }`}
          >
            {l.texto}
          </button>
        </Link>
      ) : (
        <button
          key={index}
          onClick={l.link}
          className={`Button ${l.estatus === 2 && "Completed"} ${
            l.estatus === 1 && "Proceso"
          }`}
        >
          {l.texto}
        </button>
      )
    );
};
/**
 * Crear un elemento Fila para el componente Lista
 * 
 * @param {object} props - Propiedades
 * @param {string} props.nombre - El texto a colocar en la fila
 * @param {boolean} props.estatus - El estatus del elemento a representar en la fila
 * @param {string | string[]} props.link - El o los links a mostrar en la fila
 * @param {boolean=} [props.subfila=false] - bandera para saber si es una fila padre o hija
 * 
 * @category Votación
 * @version 1.2.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Fila({ nombre, estatus, link, subfila = false }) {
  return (
    <div className={`Indicador ${subfila && "subfila"}`}>
      <Typography component="div" variant="subtitle2" className="nombre">
        {nombre}
      </Typography>

      <div className="estatus">{link ? getLinks(link, estatus) : null}</div>
    </div>
  );
}

export default Fila;
