/**
 * @module Redux/Reducers
 */

/**
 * Reducer usado por redux para manipular las rondas actuales
 * 
 * @param {array} [state=[]] - Estado inicial
 * @param {object} action - parametros para realizar
 * @param {string} action.type la accion a realizar
 * @param {*=} action.payload datos para realizar la acción
 * 
 * @returns {array} - Estado modificado
 * 
 * @version 1.0.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
const rondasReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_RONDAS":
      return (state = action.payload);
    case "DELETE_RONDAS":
      return (state = []);
    default:
      return state;
  }
};

export default rondasReducer;
