import React from "react";
import "../Dialog.css";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Button,
  DialogActions,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import parse from "html-react-parser";
/**
 * Funcion para habilitar animación de entrada vertical
 * 
 * @category Dialogs
 * @version 1.0.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
/**
 * Modal para mostrar la introducción al ejercicio
 *
 * @param {object} props - Propiedades
 * @param {boolean} props.flag_open - bandera para mostrar o no el modal
 * @param {function} props.handleClose - Callback para cerrar el modal
 * @param {string=} props.ejercicioInstrucciones html plano a mostrar en el modal
 *
 * @category Dialogs
 * @version 1.3.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
export function Introduccion({
  flagOpen,
  handleClose,
  ejercicioInstrucciones,
}) {
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={flagOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <div className="Login_logo_section">
          <img
            alt="logo_nuevo_leon"
            className="Login_logo"
            src="https://delphiconl.s3.us-east-2.amazonaws.com/front/nuevo_leon_logo.svg"
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="informacion_dialogo">
          <Typography>
            {ejercicioInstrucciones && parse(ejercicioInstrucciones)}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={handleClose}>
          ¡Empecemos!
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  ejercicioInstrucciones: state.ejercicio.instrucciones,
});

export default connect(mapStateToProps)(Introduccion);
