import React from "react";
import "./AdminContainer.css";
/**
 * Elemento que encapsula un componente para corresponder en el layout de administración
 * 
 * @category Helpers
 * @version 1.0.0
 * @param {Object} props
 * @param {Object} props.children - el componente a renderizar
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function AdminContainer({ children }) {
  return <div className="AdminContainer">{children}</div>;
}

export default AdminContainer;
