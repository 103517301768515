import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from "@material-ui/core";
import React from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import "./Menu.css";
/**
 * @category Administración
 * @param {*} props
 * @version 1.0.0
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */

const ListItemLink = (props) => {
  let { pathname } = useLocation();
  return (
    <ListItem
      button
      selected={pathname === props.to}
      component={Link}
      {...props}
    />
  );
};

/**
 * Barra lateral izquierda con links a las diferentes secciones del area de administración
 * 
 * @category Administración
 * @version 1.0.5
 * @author Ing. Roberto Alonso De la Garza Mendoza
 */
function Menu() {
  let { url } = useRouteMatch();
  return (
    <Drawer variant="permanent" className="Drawer">
      <Toolbar />
      <img
        alt="logo_nuevo_leon"
        className="Login_logo"
        src="https://delphiconl.s3.us-east-2.amazonaws.com/front/nuevo_leon_logo.svg"
      ></img>
      <Divider />
      <List>
        <ListItemLink to={`${url}/dashboard`} divider>
          <ListItemText primary="En proceso" />
        </ListItemLink>
        <ListItemLink to={`${url}/administradores`}>
          <ListItemText primary="Administradores" />
        </ListItemLink>
        <Divider />
        <ListItemLink to={`${url}/ejercicios`}>
          <ListItemText primary="Ejercicios" />
        </ListItemLink>
        <ListItemLink to={`${url}/aspiraciones`}>
          <ListItemText primary="Aspiraciones" />
        </ListItemLink>
        <ListItemLink to={`${url}/objetivos`}>
          <ListItemText primary="Objetivos" />
        </ListItemLink>
        <ListItemLink to={`${url}/lineas-estrategicas`}>
          <ListItemText primary="Líneas estratégicas" />
        </ListItemLink>
        <ListItemLink to={`${url}/usuarios`}>
          <ListItemText primary="Usuarios" />
        </ListItemLink>
        <ListItemLink to={`${url}/rondas`}>
          <ListItemText primary="Rondas" />
        </ListItemLink>
        <ListItemLink to={`${url}/indicadores`}>
          <ListItemText primary="Indicadores" />
        </ListItemLink>
      </List>
    </Drawer>
  );
}

export default Menu;
